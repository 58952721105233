.module-card {
  width: 500px;
  padding: 20px;

  h4 {
    display: inline-block;
    margin-bottom: 24px;
  }

  .status {
    margin-left: 12px;
    font-size: 12px;
    color: $faded-color-light;
    font-weight: $font-weight-normal;
  }

  .product-type-code {
    font-size: $h5-font-size;

    padding-bottom: 1rem;
    border-bottom: $border-width solid $hr-border-color;
    margin-bottom: 1rem;
  }

  .identifier {
    margin-left: 12px;
    color: $primary;
    font-size: $font-size-base;
  }

  .helper-text {
    font-size: 11px;
    color: $faded-color-light;
  }
}
