@import 'main-layout';
@import 'main-sidebar';
@import 'sidebar';
@import 'content';

.safari {
  .flex-container {
    margin-right: 0 !important;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 0px;
      height: 0px;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background: transparent;
    }
  }
}

.flex-container {
  padding: 0;
  flex: 1;
  overflow-y: scroll;
  overflow-x: hidden;
  margin-right: 0px;

  &.flex {
    display: flex;
    flex-direction: column;
  }

  &.no-overflow {
    overflow-y: hidden;
    overflow-x: hidden;
    margin-right: 0;
  }

  &.padded {
    padding: 24px;
  }

  .section {
    margin-bottom: 15px;
  }

  .section:last-of-type {
    margin-bottom: 60vh;
  }
}
