.content {
  flex: 1;
  display: flex;
  flex-direction: row-reverse;
  overflow-y: auto;
  overflow-x: hidden;

  animation-name: module-loading;
  animation-duration: $transition-time / 2;
  animation-timing-function: $transition-function;
}
@keyframes module-loading {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
