.upload-document {
  width: 600px;
  min-width: 600px;
  max-width: 600px;

  &-dropzone {
    text-align: center;
    border: $border-dashed;
    padding-bottom: 24px;
    margin-bottom: 24px;
    transition: background-color $transition-time $transition-function;

    &.active {
      border-style: solid;
    }

    img {
      margin: 0 auto;
      display: block;
    }
  }

  &-file {
    padding: 8px 16px;
    border-bottom: $border;
    display: flex;

    img {
      display: inline-block;
      margin-right: 12px;
    }

    .title {
      font-weight: $font-weight-bold;
      line-height: 24px;
      max-height: 24px;
      height: 24px;
      display: inline-block;
      max-width: 45%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      position: relative;
      top: 5px;
    }

    .validation {
      margin-left: auto;
      margin-top: 5px;

      .error {
        color: $danger;
      }

      img {
        margin-left: 16px;
      }
    }

    & + .form-group {
      margin-top: 16px;
    }
  }
}

.document-modal {
  width: fit-content;
  max-width: unset !important;
  min-width: 400px;
  height: auto;

  &.pdf {
    width: 80vw;
    max-width: 80vw;
    min-width: 80vw;
    height: 90vh;
  }

  &.animated {
    .modal-content {
      .modal-header {
        span {
          pointer-events: all;

          svg {
            cursor: pointer;
            &:hover g {
              stroke: $primary;
            }
          }
        }
      }
    }
  }

  .modal-content {
    height: 100%;
  }

  .modal-body {
    display: flex;

    .document-content {
      flex: 1;
      text-align: center;

      embed {
        width: 100%;
        height: 100%;
      }

      img {
        margin: 32px;
        max-width: 70vw;
        max-height: 70vh;
      }
    }
  }
}
