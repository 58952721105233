.debit-order-billing-summary {
  text-align: right;
  tr {
    td:first-of-type,
    th:first-of-type {
      text-align: left;
    }
  }

  .total-row {
    tr:last-of-type {
      font-weight: bold;
    }
  }
}
