.slide-in-modal {
  position: fixed;
  right: 0;
  background-color: white;
  transition: 0.5s all;
  width: 0;
  overflow: hidden;
  height: calc(100vh - 54px - 54px) !important;
  box-shadow: 0 0 10px 0 rgba(61, 61, 61, 0.3);

  &-header {
    vertical-align: middle;
    display: flex;
    padding: 8px;
    height: 48px;
    border: 1px solid #dedede;

    a {
      vertical-align: middle;
      margin: auto 0 auto 0;
    }

    h5 {
      margin: auto 0 auto 16px;
    }

    select {
      margin: auto 16px auto 0;
    }

    div {
      vertical-align: middle;
    }

    .btn-outline-primary {
      margin-left: 16px;
    }
  }

  &-body {
    .master {
      height: calc(100vh - 54px - 54px - 48px) !important;
      overflow-y: scroll;
      justify-content: flex-start;
      background-color: #f6f6f6;
      border-left: 1px solid #dedede;
    }

    .detail {
      height: calc(100vh - 54px - 54px - 48px) !important;
      width: 80%;
      left: 169px;
      overflow-y: scroll;
      justify-content: flex-end;
      padding-left: 0.5rem;
      background-color: white;

      .copyBtn {
        svg:hover {
          path {
            fill: #4078fa;
          }
        }
      }
    }

    .CodeMirror {
      height: calc(100vh - 54px - 54px - 86px) !important;
      border-left: 1px solid #dedede;
    }

    &.template-editor-production-email {
      .CodeMirror {
        height: calc(100vh - 54px - 54px - 85px) !important;
        border-left: 1px solid #dedede;
      }
    }

    &.template-editor-production-sms {
      .CodeMirror {
        height: calc(100vh - 54px - 54px - 48px) !important;
        border-left: 1px solid #dedede;
      }
    }

    &.template-editor-sandbox-email {
      .CodeMirror {
        height: calc(100vh - 54px - 54px - 116px) !important;
        border-left: 1px solid #dedede;
      }
    }

    &.template-editor-sandbox-sms {
      .CodeMirror {
        height: calc(100vh - 54px - 54px - 80px) !important;
        border-left: 1px solid #dedede;
      }
    }

    &.data-export-template-editor-production {
      .CodeMirror {
        height: calc(100vh - 54px - 54px - 48px) !important;
        border-left: 1px solid #dedede;
      }
    }

    &.data-export-template-editor-sandbox {
      .CodeMirror {
        height: calc(100vh - 54px - 54px - 78px) !important;
        border-left: 1px solid #dedede;
      }
    }
  }

  &.open {
    width: 50%;
  }
}

.slide-in-modal-actions-left {
  display: flex;
  align-items: center;
  margin-left: 8px;
}

.slide-in-modal-body {
  height: 100%;
}
