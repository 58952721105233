.main-sidebar {
  display: flex;
  width: 50px;
  flex-direction: column;
  flex: 0 0 $main-sidebar-collapsed-width;
  background: $primary;

  &-icon {
    flex: 0 0 $main-sidebar-collapsed-width;
    margin-bottom: 15px;

    &:hover {
      cursor: pointer;
      background: darken($primary, 2%);
    }

    img {
      padding: $main-sidebar-collapsed-width / 4;
    }
  }

  &-content {
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  &-link {
    cursor: pointer;

    &:not(.larger) {
      a {
        &.active {
          img {
            &.inactive {
              display: none;
            }

            &.active {
              display: block;
            }
          }
        }
      }
    }

    a {
      display: block;
      padding: 16px ($main-sidebar-collapsed-width - 24px) / 2;

      img {
        width: 24px;
        height: 24px;

        &.active {
          display: none;
        }
      }
    }

    &.larger {
      padding: 16px ($main-sidebar-collapsed-width - 30px) / 2;

      img {
        width: 30px;
        height: 30px;
      }
    }
  }

  &-spacer {
    flex: 1;
  }
}
