.data-export-merge-vars-error-wrapper {
  position: absolute;
  bottom: 20px;
  background: white;
  margin-left: 50px;
  margin-right: 50px;
  z-index: 2;
  right: 0;
  left: 0;
}

.data-export-template-lean-button {
  height: 36px;
  line-height: 28px;
}

.data-export-template-modal-header {
  text-align: center;
  margin-top: 20px;
}

.data-export-template-modal-svg-wrapper {
  float: right;
  margin-right: 30px;
  cursor: pointer;
}
