.data-export-template-dropdown-button-wrapper {
  display: inline-block;
  align-items: flex-end;
}

.data-export-template-row-item {
  padding: 0 !important;
  padding-left: 5px !important;
  height: 3rem;

  &.active {
    height: 4rem;
  }
}

.slide-in-modal-body-template-editor {
  height: 100%;

  .CodeMirror {
    height: calc(100vh - 16px - 54px - 86px) !important;
    border-left: 1px solid #dedede;
  }

  &.sandbox {
    .CodeMirror {
      height: calc(100vh - 16px - 54px - 101px) !important;
      border-left: 1px solid #dedede;
    }
  }
}

.slide-in-modal-data-export-template-editor {
  top: 53px;

  &.sandbox {
    top: 83px;
  }
}

.data-export-template-details-header {
  font-size: 16px;
  font-weight: bold;
}

.new-data-export-template-form-full-width {
  width: 100%;
}

.new-data-export-template-description {
  color: #7a7a7a;
  font-size: 12px;
}

.new-data-export-template-text-input {
  height: 100px;
}

.new-data-export-template-permissions {
  vertical-align: top;
  display: flex;
}

.new-data-export-template-modal-wrapper {
  padding-right: 8px;
}

.data-export-template-form-row-value {
  font-family: 'Source Code Pro';
  height: 3rem;
  width: calc(100% - 5px);
}

.data-export-template-form-row-width-40 {
  width: 40%;

  &.center-contents {
    font-family: 'Source Code Pro';
    vertical-align: middle;
    padding: 0;
  }
}

.new-data-export-template-last-edited {
  font-size: 12px;
  line-height: 4;
  padding-left: 10px;
}

.new-data-export-template-code-mirror {
  height: calc(100% - 85px);
}

.data-export-template-overlay {
  background: white;
  border-left: none;
}

.data-export-template-overlay-inner-content {
  margin-left: -10px;
}

.data-export-template-overlay-inner-content-badges {
  display: contents;
  line-height: 2.3;
}

.data-export-template-editor-header-button {
  margin-left: auto;

  &.primary {
    color: #4078fa;
  }

  &.no-border {
    border: none !important;
  }

  &.float-right {
    float: right;
    line-height: 2.3;
  }

  &.margin-right {
    margin-right: 12px;
  }

  &.lean {
    height: 28px;
    line-height: 0px;
  }
}

.margin-left-10 {
  margin-left: 10px;
}

.data-export-template-editor-close {
  cursor: pointer;
}

.data-export-template-editor-table {
  margin: 1rem auto auto auto;
  align-self: center;
  width: 100%;
}

.data-export-template-editor-section-header {
  font-size: 16px;
  color: #3d3d3d;
  font-weight: bold;

  &.margin-top {
    margin-top: 20px;
  }
}

.add-field-button {
  margin-top: 10px;
  margin-bottom: 10px;
}

.data-export-template-details-form-headers {
  margin-top: 10px;
}

.new-data-export-template-form-padding {
  padding-left: 14px;
  padding-right: 14px;
}

.data-export-template-editor-overlay-header {
  padding-top: 3px;
  padding-bottom: 3px;
  height: 54px;
  display: flex;
  align-items: center;
  background: white;

  &.drop-shadow {
    box-shadow: 0 -12px 23px rgba(0, 0, 0, 0.4) !important;
    z-index: 2;
  }

  &.secondary {
    height: 36px;
    margin-left: -16px;
  }

  &.background-dark {
    background: #f4f4f4;
  }

  &.underline {
    border-bottom: 1px solid #c3c3c3;
  }

  &.hover {
    :hover {
      cursor: pointer;
    }
  }
}

.data-export-template-editor-form-overlay-header {
  font-size: 16px;
  line-height: 20px;
  color: #3d3d3d;
  font-weight: bold;
  padding-left: 24px;
}

.data-export-template-editor-header-badge-wrapper {
  margin-top: 18px;
  margin-left: 10px;
}

.data-export-template-editor-lean-button {
  height: 36px;
  line-height: 28px;
}

.data-export-template-editor-overlay-footer {
  padding-top: 3px !important;
  padding-bottom: 3px !important;
  padding-right: 16px;
  height: 54px;
  display: flex;
  align-items: center !important;
  margin-top: auto;
  position: relative;
  z-index: 2;
  width: 100%;
  background: white;
  box-shadow: 0 19px 38px rgba(0, 0, 0, 0.4);
}

.data-export-template-editor-footer-button-wrapper {
  padding: 5px;
  margin-left: auto;
  margin-right: 155px;

  &.no-margin-right {
    margin-right: 0px;
  }
}

.data-export-template-fields-form-filler {
  flex: 1 1 auto;
}
