.lc {
  display: flex;
  width: 100vw;
  height: 100vh;
  background: linear-gradient( $secondary, $light-blue);

  & > div {
    flex: 1;
    display: flex;
    flex-direction: column;

    & > div:first-child {
      flex: 1;
    }

    & > div:last-child {
      flex: 2;
    }
  }
}

.m {
  flex: 0 0 700px !important;
}

.l {
  background: #fff;
  flex: 0 0 auto !important;
  display: flex;
  flex-direction: row;
  box-shadow: 0 0 10px 0 #c3c3c3;
  border-radius: $border-radius;
  min-height: 500px;

  // & > div:first-child {
  //   background: #1a43bb;
  //   flex: 0 0 50%;
  //   position: relative;
  //   border-top-left-radius: $border-radius;
  //   border-bottom-left-radius: $border-radius;

  //   img {
  //     position: absolute;
  //     top: 0;
  //     bottom: 0;
  //     left: 0;
  //     right: 0;
  //     margin: auto;
  //   }
  // }

  & > div:last-child {
    flex: 0 0 50%;
    padding: 48px 40px;
    border-top-right-radius: $border-radius;
    border-bottom-right-radius: $border-radius;

    img {
      height: 32px;
      margin-bottom: 48px;
    }
  }
}

.loading-dots {
  align-items: center;
  justify-content: center;
  position: absolute;
  left: calc((100% - 40px) / 2);

  .dot-primary {
    width: 5px;
    height: 5px;
    background-color: rgba(255, 255, 255, 1);
    display: inline-block;
    border-radius: 50%;
    margin: 0 3px;
    animation-fill-mode: both;
    animation: fader-blue 1.8s infinite ease-in-out;
    vertical-align: middle;

    &.dot-one {
      -webkit-animation-delay: -0.32s;
      animation-delay: -0.32s;
    }

    &.dot-two {
      -webkit-transform: translateZ(0);
      -ms-transform: translateZ(0);
      transform: translateZ(0);
      -webkit-animation-delay: -0.16s;
      animation-delay: -0.16s;
    }
  }

  .dot {
    width: 5px;
    height: 5px;
    background-color: rgba(255, 255, 255, 1);
    display: inline-block;
    border-radius: 50%;
    margin: 0 3px;
    animation-fill-mode: both;
    animation: fader 1.8s infinite ease-in-out;
    vertical-align: middle;

    &.dot-one {
      -webkit-animation-delay: -0.32s;
      animation-delay: -0.32s;
    }

    &.dot-two {
      -webkit-transform: translateZ(0);
      -ms-transform: translateZ(0);
      transform: translateZ(0);
      -webkit-animation-delay: -0.16s;
      animation-delay: -0.16s;
    }
  }
}

@keyframes fader-blue {
  0%,
  100%,
  80% {
    background-color: rgba(255, 255, 255, 0);
  }

  40% {
    background-color: #4078fa;
  }
}

@keyframes fader {
  0%,
  100%,
  80% {
    background-color: rgba(255, 255, 255, 0);
  }

  40% {
    background-color: rgba(255, 255, 255, 1);
  }
}

.btn-loading {
  background: $primary !important;
  border: 0 !important;
  position: relative;
  height: 48px;

  &:not(:disabled) {
    & > span:last-child {
      display: none;
    }
  }

  &:disabled {
    & > span:first-child {
      color: transparent;
    }
  }
}

.button-link {
  background: none !important;
  border: none;
  padding: 0 !important;
  font-family: arial, sans-serif;
  color: #4078fa;
  cursor: pointer;
}

.button-wrapper {
  padding-top: 10px;
}

.password-reset-success {
  color: #1fc881;
  margin-bottom: 24px;
}

.banking-update-button-styling {
  color: #4078fa;
  cursor: pointer;
  margin-left: 0px !important;
}

.alert {
  margin-bottom: 1rem;
}