@import 'bootstrap/scss/tooltip';

.tooltip {
  &-inner {
    box-shadow: $tooltip-box-shadow;
    font-size: 14px;
    line-height: 1.3em;
    text-align: center;
    color: #3d3d3d;
    padding: 16px;
    margin: 16px;
  }

  .tooltip-small {
    padding: 8px;
    margin: 8px;
  }

  .arrow {
    display: none;
  }
}
