// =======================================================
// =======================================================
// ============== START BOOTSTRAP VARIABLES ==============
// =======================================================
// =======================================================

$bootstrap-sass-asset-helper: false !default;

$gray-base: #96a3b6 !default;
$gray-dark: #596981 !default;
$gray: $gray-base !default;
$gray-light: #e1ebf0 !default;
$gray-lighter: #f7f9fb !default;

$brand-primary: #4078fa !default;
$brand-primary-gradient: #5385dd !default;
$brand-primary-tint: #eef4fe !default;
$brand-primary-shade: #5285de !default;
$brand-dark: #202b40 !default;

$font-size-base: 14px !default;

//** Deprecated `$screen-xs` as of v3.0.1
$screen-xs: 480px !default;
//** Deprecated `$screen-xs-min` as of v3.2.0
$screen-xs-min: $screen-xs !default;

// Small screen / tablet
//** Deprecated `$screen-sm` as of v3.0.1
$screen-sm: 768px !default;
$screen-sm-min: $screen-sm !default;
//** Deprecated `$screen-tablet` as of v3.0.1
$screen-tablet: $screen-sm-min !default;
$grid-gutter-width: 30px !default;

// Basics of a navbar
$navbar-height: 60px !default;
$navbar-border-radius: 0px;
$navbar-padding-horizontal: floor(($grid-gutter-width / 2)) !default;
$navbar-padding-vertical: 8px !default;
$navbar-collapse-max-height: 340px !default;

$navbar-default-color: white !default;
$navbar-default-bg: $brand-primary !default;
$navbar-default-border: darken($navbar-default-bg, 6.5%) !default;

// Navbar links
$navbar-default-link-color: white !default;
$navbar-default-link-border-radius: 0px !default;
$navbar-default-link-hover-color: #ddd !default;
$navbar-default-link-hover-bg: darken(#4078fa, 4%) !default;
$navbar-default-link-active-color: white !default;
$navbar-default-link-active-bg: darken(#4078fa, 4%) !default;
$navbar-default-link-disabled-color: #ccc !default;
$navbar-default-link-disabled-bg: transparent !default;

// Navbar brand label
$navbar-default-brand-color: $navbar-default-link-color !default;
$navbar-default-brand-hover-color: darken(
  $navbar-default-brand-color,
  10%
) !default;
$navbar-default-brand-hover-bg: transparent !default;

// Navbar toggle
$navbar-default-toggle-hover-bg: #5285de !default;
$navbar-default-toggle-icon-bar-bg: white !default;
$navbar-default-toggle-border-color: transparent !default;

//== Media queries breakpoints
//
//## Define the breakpoints at which your layout will change, adapting to different screen sizes.

// Extra small screen / phone
//** Deprecated `$screen-xs` as of v3.0.1
$screen-xs: 480px !default;
//** Deprecated `$screen-xs-min` as of v3.2.0
$screen-xs-min: $screen-xs !default;
//** Deprecated `$screen-phone` as of v3.0.1
$screen-phone: $screen-xs-min !default;

// Small screen / tablet
//** Deprecated `$screen-sm` as of v3.0.1
$screen-sm: 768px !default;
$screen-sm-min: $screen-sm !default;
//** Deprecated `$screen-tablet` as of v3.0.1
$screen-tablet: $screen-sm-min !default;

// Medium screen / desktop
//** Deprecated `$screen-md` as of v3.0.1
$screen-md: 992px !default;
$screen-md-min: $screen-md !default;
//** Deprecated `$screen-desktop` as of v3.0.1
$screen-desktop: $screen-md-min !default;

// Large screen / wide desktop
//** Deprecated `$screen-lg` as of v3.0.1
$screen-lg: 1200px !default;
$screen-lg-min: $screen-lg !default;
//** Deprecated `$screen-lg-desktop` as of v3.0.1
$screen-lg-desktop: $screen-lg-min !default;

// So media queries don't overlap when required, provide a maximum
$screen-xs-max: ($screen-sm-min - 1) !default;
$screen-sm-max: ($screen-md-min - 1) !default;
$screen-md-max: ($screen-lg-min - 1) !default;

//== Grid system
//
//## Define your custom responsive grid.

//** Number of columns in the grid.
$grid-columns: 12 !default;
//** Padding between columns. Gets divided in half for the left and right.
$grid-gutter-width: 30px !default;
// Navbar collapse
//** Point at which the navbar becomes uncollapsed.
$grid-float-breakpoint: $screen-sm-min !default;
//** Point at which the navbar begins collapsing.
$grid-float-breakpoint-max: ($grid-float-breakpoint - 1) !default;

//** Horizontal offset for forms and lists.
$component-offset-horizontal: 180px !default;
//** Text muted color
$text-muted: $gray-light !default;
//** Abbreviations and acronyms border color
$abbr-border-color: $gray-light !default;
//** Headings small color
$headings-small-color: $gray-light !default;
//** Blockquote small color
$blockquote-small-color: $gray-light !default;
//** Blockquote font size
$blockquote-font-size: ($font-size-base * 1.25) !default;
//** Blockquote border color
$blockquote-border-color: $gray-lighter !default;
//** Page header border color
$page-header-border-color: $gray-lighter !default;
//** Width of horizontal description list titles
$dl-horizontal-offset: $component-offset-horizontal !default;
//** Point at which .dl-horizontal becomes horizontal
//** Horizontal line color.
$hr-border: $gray-lighter !default;
