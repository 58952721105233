.sidebar-summary-card {
  border-top: solid $border-width $border-color;
  border-bottom: solid $border-width $border-color;
  padding: 16px 8px;

  margin-left: -8px;
  margin-right: -8px;

  & + & {
    border-top-width: 0;
  }

  a {
    font-weight: $font-weight-bold;

    &:hover span svg g {
      fill: $btn-primary-hover-background;
    }
  }

  svg {
    float: right;
    margin-top: 3px;
  }
}
