@import '../product-module-claims-schema/styles/list-component/styles.scss';

.round-button-custom {
  border-radius: 40px;
  height: 40px;
  width: 40px;
  min-width: 40px;
}

.claim-wizard {
  min-height: 500px;
  box-shadow: $card-box-shadow;
  display: flex;
  flex-direction: column;
  border-radius: $border-radius;

  &-tabs {
    flex: 0 0 48px;
    display: flex;
    background: #f6f6f6;

    &:first-child {
      border-top-left-radius: $border-radius;
    }
  }

  &-tab {
    flex: 0 1 16.6667%;
    min-width: 180px;
    text-align: center;
    font-size: 20px;
    font-weight: normal;
    line-height: 48px;
    user-select: none;

    &:not(.active) {
      background: #f6f6f6;

      &:not(.disabled):hover {
        transition: all $transition-time $transition-function;
        color: $primary;
        cursor: pointer;
      }
    }

    span {
      padding-bottom: 12px - $border-width;
      border-bottom: $border-width solid transparent;
      transition: all $transition-time $transition-function;
    }

    &.active {
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
      background-color: $white;

      span {
        padding-bottom: 12px - $border-width;
        border-bottom: $border-width solid $primary;
      }
    }

    &.disabled {
      color: #c3c3c3;

      &:hover {
        cursor: not-allowed;
      }
    }
  }

  &-container {
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  &-content {
    flex: 1;
    padding: 32px;

    &.capture {
      display: flex;
      flex-direction: column;

      p {
        max-width: 460px;
        margin-bottom: 24px;
      }

      .entry {
        flex: 0 0 36px;
        line-height: 36px;

        img {
          margin-right: 12px;
        }

        & > div {
          position: relative;
          top: 1px;
          display: inline-block;
          height: 17px;
          line-height: 17px;
        }
      }
    }
  }

  &-footer {
    flex: 0 0 80px;
    background: #f6f6f6;
    display: flex;
    padding: 16px 16px 16px 32px;
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;

    @media (max-width: 850px) {
      display: block;
    }


    .phase {
      font-size: 20px;
      font-weight: 300;
      flex: 1;
      line-height: 48px;
    }

    .footer-note {
      align-self: center;
      margin-right: 5px;
    }
  }
}

.claim-payout-margin {
  margin-bottom: 10px !important;
  margin-top: -5px;
}

.claim-payout-no-margin {
  margin-bottom: 0px !important;
  margin-top: -5px;
}

.claim-payout-row-item {
  line-height: 28px;
  margin-bottom: 0px;
}

.claim-payout-summary-header {
  font-weight: bold;
  padding-top: 15px;
}

.existing-payout-requests-header {
  padding-top: 20px;
  padding-bottom: 10px;
}

.loading-table-empty {
  border-bottom: none;
  padding-top: 5px;
  padding-bottom: 5px;
}

.table-blue-background {
  background-color: #4078fa;
}

@import 'review/claim-wizard-review';
