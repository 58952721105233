.filter-list {
  &-container {
    position: relative;
    display: flex;
    flex-direction: column;
  }

  &-value {
    height: 32px;
    line-height: 32px;
    border-radius: 4px;
    flex: 1;
    margin-left: -8px;
    padding-left: 8px;
    margin-right: -8px;
    padding-right: 8px;
    margin-bottom: 4px;
    user-select: none;

    &:last-child {
      margin-bottom: 0;
    }

    &.active {
      background: $disabled-button-color;
    }

    &:hover {
      cursor: pointer;
      color: $primary;
    }
  }
}
