.table-cell-multiple {
  & > div {
    display: inline-block;

    div:first-child {
      font-weight: $font-weight-bold;
      margin-bottom: 2px;
    }

    div:last-child {
      color: $box-shadow-color;
      font-weight: 300;
    }
  }

  & > span {
    display: inline-block;
    vertical-align: super;
    position: relative;
    top: -3px;
    margin-right: 8px;
  }
}

.table-cell-single {
  display: flex;
  align-items: center;
}

.table-cell-single-link {
  display: flex;
  align-items: center;
  color: #4078fa;
}
