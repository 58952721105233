.billing-header {
  width: 180px;
}

.billing-detail-container {
  display: flex;
  justify-content: space-between;
  border-bottom-color: black;
  border-bottom-style: solid;
  border-bottom-width: 0.5px;
  padding-bottom: 5px;
  padding-top: 5px;
}

.billing-detail-title {
  font-size: 14px;
  font-weight: bold;
}
