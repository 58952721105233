@import 'bootstrap/scss/modal';

.modal-dialog {
  // the default modal margin with 24px added from a legacy style that had to be removed
  margin-top: 52px;

  &.animated {
    .modal-content {
      &::before {
        background-size: 200% 100%;
        animation: BackgroundGradient 1.3s linear infinite;
        animation-direction: reverse;
      }

      .modal-header {
        span {
          pointer-events: none;
          svg {
            cursor: unset;
            g {
              stroke: $disabled-button-color;
            }
          }
        }
      }
    }
  }

  &.danger-animation {
    .modal-content {
      &::before {
        background: linear-gradient(to right, #fd3238 0%, #4078fa 25%, #fd3238 50%, #4078fa 75%, #fd3238 100%);
        background-size: 400% 100%;
      }
    }
  }

  @media (min-width: 576px), screen {
    max-width: 380px;
  }
}

.modal-content {
  box-shadow: $modal-content-box-shadow-xs;

  &::before {
    content: ' ';
    display: inline-block;
    width: 100%;
    height: 8px;
    background: linear-gradient(to right, $primary 0%, $light-blue 25%, $primary 50%, $light-blue 75%, $primary 100%);
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    background-size: 400% 100%;
  }
}

@keyframes BackgroundGradient {
  from {
    background-position-x: 0;
  }

  to {
    background-position-x: 100%;
  }
}

.modal-header {
  padding-top: 32px;
  padding-left: 32px;
  padding-bottom: 32px;
  padding-right: 80px;
  position: relative;

  span {
    svg {
      position: absolute;
      top: 36px;
      right: 32px;

      &:hover {
        cursor: pointer;
        g {
          stroke: $primary;
        }
      }
    }
  }
}

.modal-title {
  font-size: $h3-font-size;
  font-weight: $h3-font-weight;
}

.modal-body {
  padding: 0 32px;

  form {
    .form-group:last-of-type {
      margin-bottom: 24px;
    }

    .form-group .input-wrapper.inline + label {
      margin-bottom: 0 !important;
    }
  }

  .modal-body-error {
    color: $danger;
    padding: 8px;
    margin-bottom: 16px;
    background: rgba($danger, 0.2);
    border-radius: 4px;
  }
}

.modal-footer {
  padding: 32px;
  display: unset;

  &.right-aligned {
    display: flex;
    button {
      min-width: 140px;
    }
  }

  &.vertical {
    display: flex;
    flex-direction: column;

    button {
      width: 100%;
    }

    &.flex-row {
      flex-direction: row;
    }
  }
}

/*
 * Due to the way reactstrap renders the backdrop outside of the modal root, we have to add the styles here.
 * Original reference can be found at /rootstrap/modal-form/styles
 */
.modal-backdrop.form-modal-backdrop.show {
  opacity: 1;
  background-color: #fff;
}
