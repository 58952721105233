.collection-request-input {
  margin: 16px;
}

.coupon-table-action {
  float: right;
  height: 48px;
}

.coupon-table-margin-top {
  margin-top: 30px;
}

.refund-request-loading-state,
.collection-request-loading-state {
  height: 100px;
  width: 100%;
  background-color: #f6f6f6;
  border-radius: 5px;
  margin-top: 10px;
  margin-bottom: 10px;
}
