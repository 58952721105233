.empty-row-title {
  display: flex;
  margin-bottom: 10px;
  margin-right: 40px;
  align-items: center;
  padding-left: 15px;
  > div {
    height: 24px;
    border-radius: 4px;
    display: inline-block;
    margin: 0 4px;
    background: linear-gradient(to right, #e3e3e3, #f8f8f8, #e3e3e3);

    background-size: 200% 200%;
    animation: wave-grey 2s linear infinite, fade-in 0.8s;
  }
}
