.list-empty-state {
  padding: 48px;
  @include fadeInAnimation;

  div,
  img {
    display: inline-block;
  }

  img {
    max-width: 600px;
  }

  div {
    width: 300px;
    position: relative;
    top: 40px;

    h4:first-child {
      margin-bottom: 8px;
    }

    h4 + h4 {
      font-weight: 300;
    }
  }
}

.list-empty-state-claims {
  padding: 48px;
  @include fadeInAnimation;

  div,
  img {
    display: inline-block;
  }

  img {
    max-width: 600px;
  }

  div {
    width: 300px;
    position: relative;
    top: 40px;

    h4:first-child {
      margin-bottom: 8px;
    }

    h4 + h4 {
      font-weight: 300;
    }
  }
  display: flex;
  align-items: center;
}
