.single-page {
  .logo-bar {
    padding-left: 15px;
    padding-top: 15px;

    svg {
      .logo-icon {
        fill: $brand-primary;
      }
      .logo-word {
        fill: $brand-dark;
      }
    }
  }

  .logo-bar.blue-nav {
    background-color: $brand-primary;
    padding: 14px 0px 9px 15px;

    svg {
      .logo-icon {
        fill: white;
      }
      .logo-word {
        fill: white;
      }
    }
  }

  .application {
    margin-top: 24px;

    .panel {
      &.complete {
        background-color: $brand-dark;
        border: 1px solid $brand-dark;

        .content {
          display: flex;
          justify-content: center;
          align-items: center;
          text-align: center;

          h3 {
            color: white;
            line-height: 30px;
          }

          p {
            font-weight: 300;
          }
        }
      }
    }
  }

  .container {
    font-size: 16px;
    max-width: 940px;

    @media (max-width: $screen-xs) {
      width: 100%;
    }

    h1 {
      color: $brand-dark;
      font-size: 28px;
      margin-bottom: 15px;
      @media (min-width: $screen-xs) {
        white-space: nowrap;
      }
    }
    form {
      @media (min-width: $screen-xs) {
        max-width: 240px;
      }
    }
    button,
    input {
      margin-top: 26px;
    }
    .input-group {
      button,
      input {
        margin-top: 0;
      }
    }
    .image-container {
      text-align: right;
      padding: 20px;
      @media (max-width: $screen-sm) {
        text-align: center;
      }
    }
  }

  .single-page-card {
    .account-details {
      max-width: 425px;
    }

    @media (min-width: $screen-sm) {
      margin-top: 60px;
    }

    > .row {
      display: flex;

      .content {
        width: 100%;
        padding: 30px;

        &.kyc-documents {
          text-align: center;
        }

        .dropzone {
          width: 100%;
          min-height: 200px;
          border: 2px $gray-light;
          border-radius: 5px;
          text-align: center;
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;

          &.image {
            background-color: $gray-lighter;

            .overlay {
              position: absolute;
              top: 0;
              bottom: 0;
              left: 0;
              right: 0;
              height: 100%;
              width: 100%;
              background-color: black;
              opacity: 0;
              color: white;
              justify-content: center;
              align-items: center;
              transition: 0.5s ease;
              display: flex;

              .content {
                opacity: 1;
              }
            }
          }

          &:hover {
            background-color: $gray-lighter;

            .overlay {
              opacity: 0.7;
            }
          }

          &.loading-block {
            &:hover {
              background-color: transparent;
            }
          }
        }

        &.message {
          text-align: center;
          color: $gray-dark;
          font-size: 18px;
        }
      }

      > [class*='col-']:first-child {
        background-color: $brand-dark;
        border-radius: 5px 0px 0px 5px;
        min-height: 450px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        color: white;

        h2 {
          color: white;
          font-size: 24px;
          line-height: 30px;
        }

        p {
          font-weight: 300;
        }
      }

      > [class*='col-']:nth-child(2) {
        background-color: white;
        border-radius: 0px 5px 5px 0px;
        min-height: 450px;
        display: flex;
        align-items: center;
        justify-content: center;

        input {
          margin-top: 0px;
        }

        form {
          max-width: 100%;
        }

        button {
          min-width: 40%;
        }
      }
    }

    .verify-number {
      button {
        width: 100%;
        text-align: center;
      }

      .placeholder-button {
        background-color: $gray-light;
        color: $gray-light;
        border: 1px solid $gray-light;
        width: 100%;
      }

      .placeholder-label {
        width: 80px;
        height: 15px;
        background-color: $gray-light;
        border-radius: 9px;
      }

      .placeholder-input.form-control {
        background-color: transparent;
      }
    }
  }

  .state {
    text-align: center;
    background: $brand-dark;
    color: white;
    min-height: 450px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;

    h2 {
      color: white;
      font-size: 24px;
      line-height: 30px;
    }

    p {
      font-weight: 300;
    }
  }
}

@media (max-width: $screen-sm) {
  .single-page {
    padding: 24px;

    .logo-bar {
      display: none;
    }

    .container {
      margin-top: 0px;
      font-size: 14px;

      .single-page-card {
        > .row {
          .content {
            padding: 24px 12px;

            h2 {
              font-size: 20px;
              margin: 10px 0px;
              line-height: 30px;
            }

            .verify-number {
              button {
                margin-top: 0px;
              }

              form {
                padding-bottom: 20px;
              }
            }
          }

          > [class*='col-']:first-child {
            border-radius: 5px 5px 0px 0px;
            min-height: auto;
          }

          > [class*='col-']:nth-child(2) {
            padding-top: 24px;
            padding-bottom: 24px;
            border-radius: 0px 0px 5px 5px;
            min-height: auto;

            .row {
              > [class*='col-']:first-child {
                padding-bottom: 12px;
              }

              > [class*='col-']:nth-child(2) {
                .has-feedback {
                  position: initial;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: $screen-xs) {
  .single-page {
    .container {
      padding: 0px;

      .single-page-card {
        margin: -24px -12px;

        > .row {
          button {
            width: 100%;
          }

          > [class*='col-']:first-child {
            border-radius: 0px;
          }

          > [class*='col-']:nth-child(2) {
            border-radius: 0px;
          }
        }

        .state {
          border-radius: 0px;
        }
      }
    }
  }
}
