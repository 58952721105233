.add-user-form {
  .form-group,
  label {
    margin-bottom: 0 !important;
  }

  //please note: this is a VERY flaky selector, but there are no other immediate options. This should be resolved when refactored
  h4 + .form-group > div {
    border: 1px solid #DEDEDE;
    border-radius: 4px;
    padding: 24px;
  }

  .form-check + .form-check {
    margin-top: 24px;
  }

  .form-check-label div {
    color: #212529;
    font-size: 16px;
  }

  small {
    display: block;
    margin-top: 8px;
    margin-bottom: 0;
    font-weight: normal;
    font-size: 14px;

    &:not(:first-of-type) {
      margin-left: 25px;
    }
  }

  .form-group:first-child {
    label {
      margin-bottom: 8px !important;
    }
  }
}

.invite-user-form {
  .form-group,
  label {
    margin-bottom: 0.2rem !important;
  }

  //please note: this is a VERY flaky selector, but there are no other immediate options. This should be resolved when refactored
  br + h4 + .form-group > div {
    border: 1px solid #DEDEDE;
    border-radius: 4px;
    padding: 24px;
  }

  .form-check + .form-check {
    margin-top: 24px;
  }

  .form-check-label div {
    color: #212529;
    font-size: 16px;
  }

  small {
    display: block;
    margin-top: 8px;
    margin-bottom: 0;
    font-weight: normal;
    font-size: 14px;

    &:not(:first-of-type) {
      margin-left: 25px;
    }
  }

  .form-group:first-child {
    label {
      margin-bottom: 8px !important;
    }
  }
}

.nav-tabs {
  border-bottom: 1px solid #dee2e6;
}
.nav-tabs .nav-item {
  margin-bottom: -1px;
}
.nav-tabs .nav-link {
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  font-size: 16px;
  font-weight: bold;
}
.nav-tabs .nav-link:hover {
  border-color: white white #4078fa white;
  color: black !important;
}
.nav-tabs .nav-link:focus {
  border-color: white white #4078fa white;
}
.nav-tabs .nav-link.disabled {
  color: #6c757d;
  background-color: transparent;
  border-color: transparent;
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #495057;
  background-color: #fff;
  border-color: white white #4078fa white;
}
.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.add-team-member-header-wrapper {
  min-width: 480px;
  max-width: 700px;
  width: 700px;
  padding-top: 24px;
}

.completed-new-user-card {
  margin-top: 14px;
  margin-bottom: 110px;
}

.completed-user-card-wrapper {
  min-width: 480px;
  max-width: 700px;
  width: 700px;
}

.completed-user-card-center {
  display: flex;
  justify-content: center;
}

.completed-user-card-footer-wrapper {
  display: flex;
  flex-direction: row-reverse;
}

.completed-user-footer-wrapper {
  position: fixed;
  bottom: 0;
  width: 100%;
  margin-left: 14px;
}

.user-active-link {
  margin-bottom: 12px;
  resize: none;
}

.inter-component-padding {
  padding-bottom: 12px;
}

.invite-user-spacing {
  margin-bottom: 110px;
}

.users-error-modal-margin {
  margin-bottom: 40px;
}

.users-parent-wrapper {
  justify-content: center;
  overflow-y: scroll;
}

.users-parent-padding {
  padding-top: 20px;
}

.user-badge {
  background: #e9e9e9;
  color: #3d3d3d;
  font-weight: normal;
}

.export-users-summary,
.adapter-summary {
  padding: 24px 0 0 24px;
}

.users-export-details-card {
  .card-content {
    padding: 0;
    margin: 0;

    .heading {
      padding: 24px;
      background-color: #f6f6f6;
      display: flex;
      justify-content: space-between;

      .title {
        h4 {
          margin-bottom: 0;
          line-height: 32px;
        }

        .paused-badge {
          margin-left: 8px;
        }
      }

      .created-by {
        text-align: right;
      }
    }

    .export-users-summary,
    .adapter-summary {
      padding: 24px 0 0 24px;
    }

    .export-users-summary-table,
    .adapter-summary-table {
      display: flex;
      justify-content: space-between;

      table {
        width: calc((100% - 24px) / 2);
        display: inline-table;
        margin-bottom: 32px;

        tbody {
          tr {
            td {
              width: 50%;
              border-bottom: $table-border;
              padding: 16px 4px;

              &:first-child {
                font-weight: 600;
              }
            }
          }
        }
      }
    }
  }
}
