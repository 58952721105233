@import 'views/flow/claim-wizard';
@import 'views/claim-list/filter-bar.scss';

.empty-policy-warning {
  img {
    margin-right: 8px;
    position: relative;
    top: -1px;
  }

  margin-bottom: 32px;
}

.halved-inputs {
  display: flex;
  flex-wrap: wrap;
  > .form-group {
    flex: 1 0 45%;
    max-width: 45%;
    margin: 10px;
  }

  > h5 {
    flex: 1 0 100%;
    font-size: 20px;
    color: #3d3d3d;
  }

  > h5:not(:first-child) {
    padding-top: 24px;
    border-top: solid 2px #eaeaea;
    margin-top: 24px;
  }
}

.claim-review-checklist {
  .claim-review-row:not(:last-child) {
    border-bottom: solid 2px #eaeaea;
  }
}

.review-note-container {
  padding: 0px;
}

.claim-review-row {
  background: #f6f6f6;
  padding: 16px;
  > .heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 8px;
    margin-bottom: 0;

    .marked-by {
      padding-top: 8px;
      color: #7a7a7a;
    }

    > span {
      margin-right: 32px;
      text-align: justify;
    }

    > .checkboxes {
      display: flex;
      cursor: pointer;
      > *:not(span) {
        display: inline-block;
        width: 32px;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #c3c3c3;
      }
      > .cross {
        border-radius: 4px 0 0 4px;
        &.checked {
          background: #fd3238;
        }
        &:hover {
          background: #fd3238;
        }
      }

      > .check {
        border-radius: 0 4px 4px 0;
        &.checked {
          background: #1fc881;
        }
        &:hover {
          background: #1fc881;
        }
      }

      > span {
        margin: 0 0 0 16px;
        height: 32px;
        width: 80px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }

      .notes-toggle:hover {
        color: #064ae7;
      }
    }
  }
}

.checklist-item-note {
  background: #eaeaea;
  padding: 8px;
  margin-top: 8px;
  span {
    display: inline-block !important;
  }
  > small {
    color: #3d3d3d;
    margin-bottom: 8px;
  }
  > p {
    margin-top: 8px;
    margin-bottom: 0;
    color: #7a7a7a;
    font-size: 14px;
  }
}

.note-input {
  margin: 8px 0 16px;
  img {
    margin-right: 8px;
    position: relative;
    top: -1px;
  }
}

.confirm-claim-close {
  display: flex;

  &.hide {
    display: none;
  }

  img {
    margin-left: 12px;
    margin-right: 12px;
  }

  span {
    font-size: 12px;
    font-weight: $font-weight-bold;
    line-height: 1.5;
  }
}

.annuity-claim-card-header {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
}

.annuity-request-text-wrapper {
  font-size: 12px;
  margin-right: 4px;
  font-family: 'Source Code Pro', monospace;
}

.link-policy-overlay {
  flex: 5;
  min-width: 800px;
  max-width: 816px;
}
