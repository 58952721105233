$start-opacity: 0.01;
$start-opacity-img: 0.25;
$animation-time: 150ms;

.text-enter {
  opacity: $start-opacity;

  img,
  .flag-icon {
    opacity: $start-opacity-img;
    filter: grayscale(100%);
  }
}

.text-enter-active {
  opacity: 1;
  transition: all $animation-time ease-out;

  img,
  .flag-icon {
    opacity: 1;
    filter: grayscale(0%);
  }
}

.text-exit {
  opacity: 1;

  img,
  .flag-icon {
    opacity: 1;
    filter: grayscale(0%);
  }
}

.text-exit-active {
  opacity: $start-opacity;
  transition: all $animation-time ease-out;

  img,
  .flag-icon {
    opacity: $start-opacity-img;
    filter: grayscale(100%);
  }
}

.sidebar {
  .transition-pending {
    .transition-text {
      background: darken(#f6f6f6, 5%);
    }
  }
}

.transition {
  &-pending {
    color: transparent;
    display: inline-flex;

    img,
    .flag-icon {
      opacity: $start-opacity-img;
      filter: grayscale(100%);
    }

    .transition-text {
      background: #f6f6f6;
      opacity: 0.8;
      border-radius: 5px;
      flex: 1;
    }

    *:not(.transition-text) {
      display: none;
    }
  }
}

.transition-text {
  vertical-align: middle;
}

.badge {
  .transition-pending .transition-text {
    background: transparent !important;
  }
}
