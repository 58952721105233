$card-border-color: $border-color;
$card-border: solid $border-width $card-border-color;
$card-padding: 24px;
$card-footer-height: 48px;
$card-button-spacing: 8px;
$card-faded-text-color: $faded-color-dark;

.card {
  width: 100%;
  // border: $card-border;
  box-shadow: $card-box-shadow;
  margin-bottom: 24px;
  border-radius: 4px;
  display: flex;
  flex-direction: column;

  &-content {
    padding: $card-padding;
    flex: 1;

    .btn + .btn {
      margin-left: $card-button-spacing;
    }
  }

  &-footer {
    border-top: $card-border;
    height: $card-footer-height;
    line-height: $card-footer-height;
    padding-left: $card-padding;
  }
}

.pending-action-card {
  width: 100%;
  height: 120px;
  border: $card-border;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f6f6f6;
  font-weight: 500;
  color: grey;
  text-align: center;
}

.inline-card-container {
  display: flex;

  .card {
    &:first-child {
      margin-right: 8px;
    }
    &:last-child {
      margin-left: 8px;
    }
  }
}
