.table-badge {
  text-transform: uppercase;
  font-weight: $font-weight-bold;
  font-size: $table-header-font-size;

  &.primary {
    color: $primary;
  }

  &.info {
    color: $info;
  }

  &.success {
    color: $success;
  }

  &.warning {
    color: $warning;
  }

  &.danger {
    color: $danger;
  }

  &.purple {
    color: $purple;
  }

  &.black {
    color: #7a7a7a;
  }

  &.blue {
    color: $dark-blue;
  }

  &.yellow {
    color: $yellow;
  }
}
