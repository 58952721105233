.chevron {
  position: relative;
  opacity: 0;
  display: inline-block;
  height: $table-chevron-size;
  width: $table-chevron-size;
  margin-left: 4px;
}

.chevron:after,
.chevron:before {
  position: absolute;
  display: block;
  content: '';
  border: $table-chevron-size / 2 solid transparent;
}
/* Replace all text `top` below with left/right/bottom to rotate the chevron */
.chevron:before {
  top: $table-chevron-size / 2 - 1px;
  border-top-color: $table-active-color;
}

.chevron:after {
  top: ($table-chevron-size - 2px) / 4;
  border-top-color: #fff;
}
