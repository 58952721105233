.entity-card {
  &.associated {
    box-shadow: unset;
    border: $border;

    .entity-card-title {
      .name {
        margin-right: 24px;
        margin-bottom: 0;
        flex: 1;
      }

      .id-number {
        height: $h3-font-size + 4px;
        line-height: $h3-font-size + 4px;
        display: inline-block;
        margin-bottom: 0;
        color: $card-faded-text-color;
        flex: 1;
      }
    }
  }

  &.margin {
    margin-left: 24px;
    width: calc(100% - 24px);

    .card-content {
      background: #f6f6f6;
    }
  }

  &-title {
    display: flex;
    margin-top: 16px;

    .name {
      margin-right: 24px;
      margin-bottom: 0;
    }
  }

  &-title {
    display: flex;
    margin-top: 16px;
    margin-bottom: 16px;
    position: relative;
    flex-wrap: wrap;

    .name {
      margin-right: 24px;
      margin-bottom: 0;
    }

    .country {
      height: $h3-font-size + 4px;
      line-height: $h3-font-size + 4px;
      margin-right: 8px;
      display: inline-block;

      & > span svg {
        height: 22px;
        // width: 24px;
      }
    }

    .id-number {
      height: $h3-font-size + 4px;
      line-height: $h3-font-size + 4px;
      display: inline-block;
      margin-bottom: 0;
      color: $card-faded-text-color;
    }

    .status {
      flex: 0 0 auto;
      //position: absolute;
      //right: 0;
    }
  }

  .person-name {
    margin-bottom: 8px;
    margin-top: 24px;
  }

  &-relation {
    font-weight: bold;
    margin-bottom: 24px;
  }

  &-contact {
    display: flex;

    .email,
    .cell,
    .address {
      line-height: 24px;
      img {
        margin-right: 8px;
      }
    }

    &.address {
      margin-top: 15px;
    }

    .email {
      margin-right: 32px;
    }

    & + .btn {
      margin-top: 32px;

      & + .btn {
        margin-top: 32px;
      }
    }
  }

  &-entity-summary {
    color: $box-shadow-color;
    font-size: 12px;
    margin-top: 20px;
  }

  .card-footer {
    line-height: 46px !important;

    img {
      margin-right: 16px;
      position: relative;
      top: -1px;
    }
  }
}
