$btn-border-width: $border-width;
$btn-primary-hover-background: $primary;
$btn-primary-hover-border: $primary;
$btn-primary-active-background: #1a43bb;
$btn-primary-active-border: $btn-primary-active-background;

@import 'bootstrap-buttons';
// @import 'bootstrap/scss/button-group';
@import 'bootstrap/scss/buttons';

.btn-primary {
  @include button-variant(
    $primary,
    $primary,
    $btn-primary-hover-background,
    $btn-primary-hover-border,
    $btn-primary-active-background,
    $btn-primary-active-border
  );

  &.hover,
  &:hover {
    &:not(.active):not(:active) {
      opacity: 0.65;
    }
  }

  &.disabled,
  &:disabled {
    background-color: $disabled-button-color;
    border-color: $disabled-button-color;
    color: $white;
  }
}

.btn-outline-primary {
  @include button-outline-variant($primary, $primary, $white, $primary);

  &.hover,
  &:hover {
    opacity: 0.65;
  }

  &.disabled,
  &:disabled {
    border-color: $disabled-button-color;
    color: $disabled-button-color;
  }

  &:not(:disabled):not(.disabled).active,
  &:not(:disabled):not(.disabled):active {
    color: $btn-primary-active-background;
    border-color: $btn-primary-active-border;
  }
}

.btn-blank {
  @include button-outline-variant($primary, $primary, $white, $primary);
  border-width: 0;

  &.hover,
  &:hover {
    opacity: 0.65;
  }

  &.disabled,
  &:disabled {
    border-color: $disabled-button-color;
    color: $disabled-button-color;
  }

  &:not(:disabled):not(.disabled).active,
  &:not(:disabled):not(.disabled):active {
    color: $btn-primary-active-background;
    border-color: $btn-primary-active-border;
  }
}

.btn-link {
  padding: 0;
  line-height: $font-size-base;
  vertical-align: baseline;
}

.btn-docs {
  @include button-variant(#ffffff, #eaeaea, #fefefe, #eaeaea, #fdfdfd, #eaeaea);

  font-weight: bold;
  color: #202b40;

  &.hover,
  &:hover {
    &:not(.active):not(:active) {
      opacity: 0.85;
      box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);

      & + img {
        opacity: 0.85;
      }
    }
  }

  &.disabled,
  &:disabled {
    background-color: $disabled-button-color;
    border-color: $disabled-button-color;
    color: $white;
  }

  &.green {
    border-left: 4px solid #1ec881 !important;
  }

  &.purple {
    border-left: 4px solid #7f48e0 !important;
  }

  &.none {
    font-weight: normal;
    padding-right: 70px;

    & + img {
      width: 44px;
      height: 44px;
      position: relative;
      left: -70px;
      cursor: pointer;
      pointer-events: none;
    }
  }

  margin-right: 24px;
}

.btn.inline {
  display: inline-block;
}

.btn.block {
  display: block;
}

.btn + .btn {
  margin-left: 8px;
}

.button-lean {
  height: 36px;
  line-height: 28px;

  &.disabled {
    cursor: not-allowed;
  }
}
