.filter-toggle-left {
  border: 0.5px solid #c3c3c3;
  border-right: 0px;
  width: 90px;
  height: 34px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  z-index: 0 !important;
}

.filter-toggle-right {
  border: 0.5px solid #c3c3c3;
  width: auto;
  padding-top: 8px;
  padding-left: 5px;
  padding-right: 10px;
  border-radius: 0px 1px 1px 0px;
  cursor: pointer;
}

.filter-menu {
  width: 500px;
  padding-bottom: 0px;
  padding-top: 0px;
}

.filter-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.filter-header-label {
  font-size: 16px;
  font-weight: bold;
}

.filter-dropdown {
  background-color: #f6f6f6;
  padding-top: 25px;
  padding-bottom: 25px;
  padding-right: 25px;
  padding-left: 25px;
}

.filter-checkbox {
  display: flex;
  justify-content: flex-start;
  border-top: 0.5px solid #dedede;
  border-bottom: 0.5px solid #dedede;
  padding-top: 15px;
  padding-bottom: 15px;
}

.filter-checkbox-label {
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  align-items: center;
}

.filter-divider {
  border: solid 0.5px #d9d9d9;
}

.Select--multi {
  display: flex !important;
  flex-wrap: wrap;
  align-items: baseline;
}

.Select-multi-value-wrapper {
  flex-wrap: wrap;
  align-items: baseline;
  height: 31px;
}

.Select-menu-outer {
  z-index: 1000;
}

.filter-dropdown-label {
  margin-bottom: 5px;
  font-weight: bold;
}
