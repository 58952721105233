.notes-empty-state {
  padding: 24px;
  text-align: center;
  overflow: hidden;
  min-width: 318px;
  @include fadeInAnimation;

  h4 {
    margin-bottom: 18px;
  }

  img {
    position: relative;
    margin-top: -30px;
    height: 240px;
  }
}

.note {
  &-container {
    padding: 16px;
  }

  & {
    background: #fff;
    margin-bottom: 8px;
    border: $border;
    border-radius: 4px;
    font-size: 12px;
  }

  &-header {
    display: flex;
    background: #f6f6f6;
    padding: 12px;
  }

  &-content {
    padding: 12px;
    margin-bottom: 0;
    font-family: Lato;
    font-size: 14px;
  }

  .note-created-by {
    flex: 1;

    &-title {
      font-weight: $font-weight-bold;
    }
  }

  .note-created-at {
    flex: 0 0 auto;
    text-align: right;

    &-date {
      font-weight: $font-weight-bold;
    }
  }
}
