.last-run-entry {
  display: flex;
  align-items: center;

  .status-icon {
    padding-right: 16px;

    img {
      width: 16px;
    }
  }
}

.data-export-name-entry {
  display: flex;
  align-items: center;

  .lock-icon {
    padding-left: 8px;

    img {
      width: 16px;
    }
  }
}

.next-run-text {
  display: flex;
  align-items: center;

  .icon {
    padding-right: 4px;
  }
}

.new-data-export-modal-heading {
  padding-top: 32px;
}

.new-data-export-env {
  color: #7a7a7a;
  font-weight: normal !important;
}

.exports-tab-nav {
  cursor: pointer;
  padding-left: 0px;
  color: black;
  &.active {
    color: #7a7a7a;
  }
}

.standard-height-private-key-text-area {
  textarea {
    height: 56px;
  }
}

.double-height-private-key-text-area {
  textarea {
    height: 112px;
  }
}

.feature-not-available-in-sandbox-export-templates {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: left;
  background: none;
  z-index: 0;
  max-width: 1300px;
  margin-left: auto;
  margin-right: auto;

  div {
    font-size: 1.5em;
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 48px;
    max-width: 600px;
  }

  h4 {
    margin-bottom: 8px;
  }

  p {
    font-weight: 300;
  }

  button {
    max-width: 169px;
  }
}
