@import './policy-view';
@import './sidebar-summary-card';

.table-empty-state,
.full-empty-state {
  border-radius: 4px;

  position: relative;
  min-height: 244px;

  div {
    width: 250px;
    margin-top: 30px;
    margin-bottom: 30px;

    h3 {
      margin-bottom: 8px;
      font-size: 20px;
      font-weight: $font-weight-bold;
    }

    h2 {
      font-weight: $font-weight-light;
      margin-bottom: 0;
      font-size: 24px;
    }
  }

  img {
    height: 230px;
    position: absolute;
    right: 0;
    top: 10px;
  }
}

.table-empty-state {
  min-width: 600px;
  max-width: 650px;
  border: solid $border-width $border-color;
  padding: 32px;
  margin-bottom: 24px;
}

.full-empty-state {
  position: realtive;
  top: -40px;
  div {
    width: 300px;
    margin-top: 0;
    margin-bottom: 0;
  }
}

.search-empty-state {
  border-radius: 4px;

  position: relative;
  min-height: 244px;

  div {
    width: 250px;
    display: inline-block;
    padding-top: 65px;

    h3 {
      margin-bottom: 8px;
      font-size: 20px;
      font-weight: $font-weight-bold;
    }

    h2 {
      font-weight: $font-weight-light;
      margin-bottom: 0;
      font-size: 24px;
    }
  }

  img {
    height: 230px;
    display: inline-block;
    vertical-align: top;
    margin-right: 48px;
  }
}

tr.active {
  td {
    border-color: $primary;
    background: #f6f6f6;
  }
}

.policy-list-view-heading {
  flex: 1;
  margin-bottom: 10px;
}

.search-bar-header-wrapper {
  margin-top: 8px;
  margin-right: 0px;
}

.policies-domain-entity-table-row-icon {
  margin-right: 5px;
  height: 20px;
}
