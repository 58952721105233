@import 'bootstrap/scss/badge';

.badge {
  text-transform: uppercase;
  line-height: $badge-line-height;
  color: $white;

  &-purple {
    background: $purple;
  }

  &-black {
    color: $white;
    background: #3d3d3d;
  }

  &-blue {
    color: $white;
    background: $dark-blue;
  }

  &-yellow {
    color: #3d3d3d;
    background: $yellow;
  }
}
