.breadcrumbs {
  display: block;
  font-size: 12px;
  margin-bottom: 24px;

  .entry {
    display: inline-block;
    text-transform: uppercase;

    &:not(:last-child()) {
      &::after {
        content: ' ';
        background-image: url('/assets/images/icons/icon-12-arrow-right.svg');
        width: 8px;
        height: 12px;
        display: inline-block;
        margin: 0 8px;
        position: relative;
        top: 1px;
      }
    }
  }
}
