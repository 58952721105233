.blocks-select {
  width: '50%';
}

.error {
  border-color: #ffab00;
  border-width: 2px;
  border-style: solid;
}

.blocks-ui {
  Label {
    font-weight: bold;
  }

  display: 'block';

  h3 {
    margin-bottom: 8px;
  }

  .blocks-heading {
    display: flex;
    margin-bottom: 32px !important;

    h2 {
      margin-bottom: 0;
      margin-top: (48px - $h2-font-size) / 2;
    }
  }

  .blocks-details-card {
    .card-content {
      padding: 0;
      margin: 0;

      .heading {
        padding: 24px;
        background-color: #f6f6f6;
        display: flex;
        justify-content: space-between;

        .title {
          display: flex !important;
          height: 36px !important;

          h4 {
            display: inline-block;
            margin: 0px;
          }

          .paused-badge {
            margin-left: 8px;
          }
        }

        .created-by {
          text-align: right;
        }
      }

      .summary,
      .summary {
        padding: 24px 24px 0px 24px;
      }

      .summary-table,
      .summary-table {
        display: flex;
        justify-content: space-between;

        table {
          display: inline-table;

          tbody {
            tr {
              td {
                width: 50%;
                border-bottom: $table-border;
                padding: 16px 4px;

                &:first-child() {
                  font-weight: 600;
                }
              }
            }
          }
        }
      }
    }
  }

  .no-section-divider {
    padding: 0;

    &:not(:last-child) {
      border-bottom: none;
    }
  }

  .blocks-dropdown {
    width: 100%;
  }
}

.react-datepicker__time-box {
  width: 100% !important;
}

.react-datepicker__time-list {
  padding: 0px;
}

.react-datepicker__time-container {
  width: 100%;
}

.react-datepicker__month-container {
  width: 100%;
}

.currency-symbol {
  border-top-right-radius: 0;
  border-right-width: 0;
  border-bottom-right-radius: 0;
}

.blocks-saving-saved-badge-wrapper {
  border-radius: 22px;
  text-align: center;
  height: 18px !important;
  display: flex;
  align-items: flex-end;
  margin-left: 0px !important;
  margin-top: 0 !important;
  margin-bottom: 0px;
}

.blocks-data-saving-badge {
  background-color: #f6f6f6;
  color: gray;
  display: inline-block;
  width: 60px;
  align-items: center;
}

.blocks-data-saved-badge {
  background-color: #f6f6f6;
  color: #1fc881;
  display: inline-block;
  width: 70px;
  left: 10px;
  position: relative;
  align-items: center;
}

.blocks-saving-svg {
  margin-right: 5px;
  padding-bottom: 3px;
}

.blocks-checkbox-wrapper {
  margin: 0 0 0 24px;
}

.blocks-input-wrapper {
  align-items: flex-end;
  margin-bottom: 8px;
}

.blocks-input-checkbox-wrapper {
  height: 35px;
  margin-top: 8px;
}

.blocks-dropdown-saving-badge-wrapper {
  margin-top: 26px !important;
}

.blocks-input-date-block-badge-wrapper {
  margin-top: 32px !important;
}

.blocks-input-radio-block-badge-wrapper {
  margin-top: 0px !important;
}

.blocks-input-checkbox-block-badge-wrapper {
  margin-top: -20px !important;
}

.blocks-input-currency-block-badge-wrapper {
  margin-top: 32px !important;
}

.blocks-input-number-block-badge-wrapper {
  margin-top: 32px !important;
}

.blocks-input-paragraph-block-badge-wrapper {
  margin-top: 0px !important;
  align-items: flex-start;
}

.blocks-input-text-block-badge-wrapper {
  margin-top: 10px !important;
}

.blocks-input-time-block-badge-wrapper {
  margin-top: 32px !important;
}

.blocks-saving-inner-badge {
  margin-left: 10px;
  margin-bottom: -8px;
}

.blocks-saved-inner-badge {
  margin-bottom: -8px;
}
