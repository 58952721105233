.switch {
  &-container {
    width: 48px;
    height: 24px;
    border-radius: 16px;
    background-color: $disabled-button-color;
    position: relative;

    &:hover {
      cursor: pointer;

      .switch-button {
        background-color: #fafafa;

        &.active {
          background-color: lighten($primary, 5%);
        }
      }
    }
  }

  &-button {
    width: 21px;
    height: 20px;
    background-color: #fff;
    border-radius: 50%;
    position: absolute;
    top: 2px;
    left: 3px;
    color: transparent;
    transition: left 50ms ease-in, color 50ms ease-in 25ms;

    &.active {
      background-color: $primary;
      left: 24px;

      &::before {
        display: inline-block;
        content: ' ';
        background-image: url('/assets/images/icons/icon-16-tick-white.svg');
        background-size: 90%;
        background-position: 5%;
        position: relative;
        left: 2px;
        font-size: 12px;
        width: 21px;
        height: 20px;
      }
    }
  }

  &-label {
    flex: 1;
    line-height: 24px;
  }

  &-form-group {
    display: flex;
  }
}
