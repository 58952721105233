.page-item,
.page-link {
  &:focus {
    outline: none;
    box-shadow: none;
  }
}

table {
  transition: opacity $transition-time $transition-function;

  &.paginating {
    cursor: wait !important;
    opacity: 0.7;

    tr {
      pointer-events: none;
    }
  }
}

.pagination-disabled {
  opacity: 0.7;
  transition: opacity $transition-time $transition-function;

  .page-item {
    pointer-events: none;
  }
}

.pagination-image-button {
  &.prev {
    svg {
      transform: rotateZ(180deg);
    }
  }
}

.page-item {
  user-select: none;

  &:not(.disabled) {
    .pagination-image-button {
      svg path {
        stroke: $primary;
      }
    }
  }

  &.disabled {
    pointer-events: none;

    .pagination-image-button {
      svg path {
        stroke: #c3c3c3;
      }
    }
  }
}

.page-link:hover {
  z-index: 0;
}
