.list-copmponent-parent {
  padding-left: 15px;
  padding-right: 15px;
  width: 100%;
  background-color: white;
}

.list-component-remove-button-container {
  position: inherit;
  width: 100%;
  padding-right: 1px;
  z-index: 2;
}

.list-component-remove-button {
  float: right;
  margin-bottom: -50px;
}

.table-style-general {
  position: relative;
  margin: 10px auto;
  padding: 0;
  width: 100%;
  height: auto;
  border-collapse: collapse;
  text-align: center;
}

.nested-table-set-marging-bottom {
  margin-bottom: 1px !important;
}

.nested-table-row-wrapping {
  vertical-align: top;
  text-align: start;
  min-width: 60px;
  word-break: break-word;
}

.nested-table-float-left {
  float: left;
  text-align: left;
}

.nested-table-single-object-row {
  display: flex;
  text-align: start;
}

.nested-table-zero-padding-t-l-r {
  padding-top: 0px !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.nested-table-margin-bottom {
  margin-bottom: -1px;
}

.nested-table-remove-border-bottom {
  padding-bottom: 0px !important;
  border-bottom: none !important;
}

.nested-table-no-margin-padding-bottom {
  padding-bottom: 0px;
  margin-bottom: 0px;
}
