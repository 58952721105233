@import 'utilities/variables';
@import 'utilities/single-page-layout';
@import './settings.scss';
@import 'font-awesome';

.root-container {
  padding-top: 60px;

  .spacer {
    padding: 10px;
  }
}

.spinner {
  width: 40px;
  height: 40px;
  margin: 100px auto;
  background-color: $brand-primary;

  border-radius: 100%;
  -webkit-animation: sk-scaleout 1s infinite ease-in-out;
  animation: sk-scaleout 1s infinite ease-in-out;
}

@-webkit-keyframes sk-scaleout {
  0% {
    -webkit-transform: scale(0);
  }
  100% {
    -webkit-transform: scale(1);
    opacity: 0;
  }
}

@keyframes sk-scaleout {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0;
  }
}

.not-found {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 100vh;
}

.flush-overlay {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: #000;
  z-index: 10000;
  opacity: 0.3;
}

.navdropdown-heading a {
  border: 0 !important;
  margin-top: 16px !important;
  margin-left: 11px !important;
  margin-right: 11px !important;
  margin-bottom: 0px !important;
  font-size: 0.75em;
  font-weight: bold !important;
  padding-left: 0 !important;
  color: lighten(#596981, 20%) !important;

  &:hover {
    background: #fff !important;
    cursor: initial !important;
  }
}

.dropdown-brand-image {
  width: 15px;
  height: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: $gray-light;
  border-radius: 5px;
  overflow: hidden;
  margin-right: 10px;
  margin-left: -7px;

  float: left;
}

$box-shadow-color: #c3c3c3;
$faded-color-dark: #7a7a7a;
$border-color: #eaeaea;
$border-radius: 4px;
$border-width: 2px;
$primary: $brand-primary;
$card-faded-text-color: $faded-color-dark;
$card-box-shadow: 0 0 10px 0 $box-shadow-color;
$popover-border-width: 1px;
$popover-border-color: #fff;
$popover-box-shadow: $card-box-shadow;

$menu-border-color: #f6f6f6;

.org-popover {
  max-height: 377px;
  top: -7px !important;
  position: absolute;
  left: -156px !important;

  .user-popover-orgs-container {
    max-height: 377px - 50px - 53px - 65px;
  }

  .arrow {
    top: 87px !important;
  }

  .create-org {
    padding: 16px 16px 16px 24px;
    font-size: 14px;
    font-weight: bold;
    height: 51px;

    img {
      margin-right: 8px;
      margin-top: -3px;
    }

    a {
      color: $primary;

      &:hover {
        color: $primary;
      }
    }
  }

  &-heading {
    border-bottom: $border-width solid $menu-border-color;
    padding: 16px;
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 2px;
    height: 73px;
    line-height: 44px;
    cursor: pointer;

    &:hover {
      background: #fff;
      color: $primary;

      svg path {
        stroke: $primary;
      }
    }

    svg {
      transform: rotateY(180deg);
      margin-right: 8px;
      margin-top: -1px;
    }
  }
}

.user-popover,
.org-popover {
  width: 236px;
  box-shadow: $popover-box-shadow;
  transform: translateX(-90px) translateY(8px);
  border: 0;

  .arrow {
    left: unset !important;
    right: 24px;
  }

  .popover-content {
    padding: 0;
  }

  &-current {
    display: flex;
    flex-direction: row;
    border-bottom: $border-width solid $menu-border-color;
    padding: 16px;

    img {
      margin-right: 16px;
      width: 32px;
      height: 32px;
    }

    &-name {
      div {
        font-size: 14px;
        font-weight: bold;
        margin-bottom: 2px;
      }

      a {
        color: #7a7a7a;
        font-size: 10px;

        &:hover {
          color: $primary;
        }
      }
    }
  }

  &-links {
    border-bottom: $border-width solid $menu-border-color;
    padding: 16px;
    user-select: none;

    &:not(.no-color) {
      div,
      a {
        &.banking {
          color: #7f48e0;
        }

        &.insurance {
          color: #1fc881;
        }
      }
    }

    div,
    a {
      line-height: 27px;
      padding: 0 8px;
      display: block;

      & + div {
        margin-top: 4px;
      }

      img {
        margin-right: 8px;
        margin-top: -3px;
      }

      &:hover {
        background: #f6f6f6;
        cursor: pointer;
      }
    }
  }

  &-orgs {
    border-bottom: $border-width solid $menu-border-color;
    padding: 16px;

    &-title {
      display: flex;
      margin-bottom: 2px;
      padding-left: 8px;

      div {
        flex: 1;
        font-size: 14px;
        font-weight: bold;
      }

      a {
        font-size: 10px;
        line-height: 17px;
      }
    }

    &-container {
      max-height: 300px;
      overflow-y: auto;
    }

    &-org {
      margin: 4px 0;
      display: flex;
      padding: 4px 0 4px 8px;

      &:last-child {
        margin-bottom: 0;
      }

      &:hover {
        background: #f6f6f6;
        cursor: pointer;
      }

      .circle {
        width: 16px;
        height: 16px;
        background-color: #d8d8d8;
        margin-right: 8px;
        border-radius: 50%;
        background-size: cover;
      }

      div {
        font-size: 14px;
        line-height: 16px;
      }

      &.active {
        .circle {
          box-shadow: 0 0 1px 2px #a5ceff;
        }

        div {
          font-weight: bold;
        }
      }
    }
  }

  &-signout {
    padding: 16px 16px 16px 24px;
    font-size: 14px;
    font-weight: bold;

    a {
      color: #3d3d3d;

      &:hover {
        color: $primary;
      }
    }
  }
}

.modal-backdrop {
  z-index: 1060;
}

.modal {
  z-index: 1061;
}

::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}

::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.1);
  border-radius: $border-radius;
}

::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.3);
  border-radius: $border-radius;
}

.navbar-default {
  border-color: #2061f9;
  background-color: #4078fa;
  flex: auto;
}

.navbar-default {
  background-color: $navbar-default-bg;
  border-color: $navbar-default-border;

  .navbar-brand {
    color: $navbar-default-brand-color;
    fill: $navbar-default-brand-color;
    &:hover,
    &:focus {
      /*color: $navbar-default-brand-hover-color;*/
      background-color: $navbar-default-brand-hover-bg;
    }
  }

  .navbar-text {
    color: $navbar-default-color;
  }

  .navbar-nav {
    > li > a {
      color: $navbar-default-link-color;

      &:hover,
      &:focus {
        /*color: $navbar-default-link-hover-color;*/
        background-color: $navbar-default-link-hover-bg;
      }
    }
    > .active > a {
      &,
      &:hover,
      &:focus {
        color: $navbar-default-link-active-color;
        background-color: $navbar-default-link-active-bg;
      }
    }
    > .disabled > a {
      &,
      &:hover,
      &:focus {
        color: $navbar-default-link-disabled-color;
        background-color: $navbar-default-link-disabled-bg;
      }
    }
  }

  .navbar-toggle {
    border-color: $navbar-default-toggle-border-color;
    &:hover,
    &:focus {
      background-color: $navbar-default-toggle-hover-bg;
    }
    .icon-bar {
      background-color: $navbar-default-toggle-icon-bar-bg;
    }
  }

  .navbar-collapse,
  .navbar-form {
    border-color: $navbar-default-border;
  }

  // Dropdown menu items
  .navbar-nav {
    // Remove background color from open dropdown
    > .open > a {
      &,
      &:hover,
      &:focus {
        background-color: $navbar-default-link-active-bg;
        color: $navbar-default-link-active-color;
      }
    }

    @media (max-width: $grid-float-breakpoint-max) {
      // Dropdowns get custom display when collapsed
      .open .dropdown-menu {
        > li > a {
          color: $navbar-default-link-color;
          &:hover,
          &:focus {
            color: $navbar-default-link-hover-color;
            background-color: $navbar-default-link-hover-bg;
          }
        }
        > .active > a {
          &,
          &:hover,
          &:focus {
            color: $navbar-default-link-active-color;
            background-color: $navbar-default-link-active-bg;
          }
        }
        > .disabled > a {
          &,
          &:hover,
          &:focus {
            color: $navbar-default-link-disabled-color;
            background-color: $navbar-default-link-disabled-bg;
          }
        }
      }
    }
  }
}

.nav-stacked {
  > a {
    float: none;
    width: 100%;
    border-radius: 5px;
    min-height: 40px;
    // border: 1px solid transparent;
    > a {
      margin-top: 2px;
      margin-left: 0;
      color: black;
      line-height: 18px;
      .icon-container {
        width: 16px;
        margin-right: 6px;
        font-size: 16px;
        text-align: center;
        display: inline-block;
      }
    }
    &.active {
      background-color: #f7f9fb;
      // border: 1px solid #E1EBF0;
      font-weight: bolder;
      border-radius: 5px;
      > a {
        &:before {
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          width: 5px;
          height: 39px;
          content: '';
          background-color: #4078fa;
          border-top-left-radius: 5px;
          border-bottom-left-radius: 5px;
          border-right: 1px solid #e1ebf0;
        }
      }
    }
  }
}
