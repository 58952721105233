.search-control {
  position: relative;
  height: 48px;

  input {
    width: 300px;
    height: 48px;
    border: $border-width solid #c3c3c3;
    border-radius: 24px;

    font-size: 16px;
    font-weight: $font-weight-normal;
    display: inline-block;

    position: absolute;
    left: 0;
    z-index: 0;
    padding-left: 48px;
  }

  input:focus + .search-icon {
    svg g {
      stroke: $primary;
    }
  }

  .search-icon {
    width: 24px;
    height: 24px;
    display: inline-block;
    position: absolute;
    left: 18px;
    top: 12px;

    svg g {
      transition: stroke $transition-time $transition-function;
    }
  }

  .clear-icon {
    width: 24px;
    height: 24px;
    display: inline-block;
    position: absolute;
    left: 300px - 42px;
    top: 12px;

    &.hidden {
      display: none;
    }

    &:hover {
      cursor: pointer;
      svg g {
        stroke: $primary;
      }
    }

    svg g {
      transition: stroke $transition-time $transition-function;
    }
  }
}

.search-control-lean {
  position: relative;
  height: 48px;
  margin-right: 0px;

  input {
    border: 1px solid #c3c3c3;
    border-radius: 4px;

    font-size: 16px;
    font-weight: $font-weight-normal;
    display: inline-block;

    // position: absolute;
    left: 0;
    z-index: 0;
    padding-left: 48px;
  }

  input:focus + .search-icon {
    svg g {
      stroke: $primary;
    }
  }

  .search-icon {
    width: 24px;
    height: 24px;
    display: inline-block;
    position: relative;
    left: 18px;
    top: -29px;

    svg g {
      transition: stroke $transition-time $transition-function;
    }
  }

  .clear-icon {
    width: 24px;
    height: 24px;
    display: inline-block;
    position: relative;
    left: -6px;
    top: -29px;

    &.hidden {
      display: none;
    }

    &:hover {
      cursor: pointer;
      svg g {
        stroke: $primary;
      }
    }

    svg g {
      transition: stroke $transition-time $transition-function;
    }
  }

  .lean-icon-container {
    display: flex;
    justify-content: space-between;
  }
}
