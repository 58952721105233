.orgs {
  &-container {
    margin-top: 40px;
  }

  &-card {
    display: inline-block;
    box-shadow: $card-box-shadow;
    width: 419px;
    height: 182px;
    margin-bottom: 24px;
    margin-right: 24px;
    padding: 24px;
    border-radius: $border-radius;
    vertical-align: top;

    &:hover {
      transform: scale(1.015);
      transition: transform 150ms ease-in-out;
      cursor: pointer;
    }

    .org-image-image {
      height: 134px;
      width: 134px;
      position: relative;
      background-color: #f6f6f6;

      &.has-image {
        background-color: white;
      }

      img {
        max-width: 134px;
        max-height: 134px;
        position: absolute;
        top: 0;
        bottom: 0;
        margin: auto;
      }
    }

    &-content {
      display: flex;
    }

    &-title {
      font-size: 12px;
      font-weight: 500;
      margin-top: 2px;

      span:last-child {
        float: right;

        &.live {
          color: $success;
        }

        &.sandbox {
          color: $warning;
        }
      }
    }

    &-name {
      font-size: 24px;
      font-weight: bold;
      margin-top: 16px;
      margin-bottom: 32px;
    }

    &-description {
      color: #c3c3c3;
      max-height: 39px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }

    &-inner-content {
      display: flex;
      flex-direction: column;
      flex: 1;
    }
  }
}

.org-helper {
  &-container {
    margin-top: 24px;
  }

  &-card {
    display: inline-block;
    width: 419px;
    height: 182px;
    margin-bottom: 24px;
    margin-right: 24px;
    padding: 24px;
    border-radius: $border-radius;
    background: #f6f6f6;
    position: relative;
    vertical-align: top;

    h5 {
      font-size: 20px;
      font-weight: bold;
    }

    a {
      position: absolute;
      bottom: 24px;
      font-weight: bold;
    }
  }
}

.org-image-wrapper {
  margin-right: 32px;
}
