.payment-method {
  &-container {
    display: flex;
    margin-bottom: 24px;

    .btn {
      flex: 0 0 140px;
    }
  }

  &-card {
    flex: 1;
    margin-bottom: 0;
    margin-right: 24px;
    max-width: 650px;

    .card-content {
      justify-content: space-between;
      align-items: center;
      display: flex;
      padding: 14px 16px;
    }
  }

  &-type {
    font-weight: 600;

    &.error {
      font-weight: 400;
      cursor: pointer;

      &:hover {
        font-weight: 500;
      }
    }
  }

  &-detail {
    float: right;
  }
}

span {
  &.warning {
    color: $warning;
  }
  &.success {
    color: $success;
  }
  &.danger {
    color: $danger;
  }
}
