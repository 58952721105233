body {
  -webkit-font-smoothing: auto;
}

// #root,
.main-layout {
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

.main-layout {
  display: flex;
  animation-name: module-loading;
  animation-duration: 500ms;
  animation-iteration-count: 1;

  &-content {
    display: flex;
    flex-direction: row;
    flex: 1;
  }
}

@keyframes module-loading {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
