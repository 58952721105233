.org {
  &-container {
    display: flex;
    width: 600px;
    padding: 32px 0;
  }

  &-image {
    margin-right: 32px;

    &-image {
      height: 200px;
      width: 200px;
      position: relative;
      background-color: #f6f6f6;

      &.has-image {
        background-color: white;
      }

      img {
        max-width: 200px;
        max-height: 200px;
        position: absolute;
        top: 0;
        bottom: 0;
        margin: auto;

        &.padded {
          margin: 25px;
        }
      }
    }

    a {
      text-align: left;
    }

    small {
      color: #cecece;
    }
  }

  &-details {
    max-width: 800px;
    flex: 1;

    h3 {
      margin-bottom: 16px;
    }

    h5 {
      margin-top: 32px;
    }

    button {
      min-width: 140px;
    }

    .show-tooltip {
      pointer-events: none;
    }
  }
}
