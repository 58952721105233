.red-dot {
  color: #ff0000;
  border: 4px solid #ff0000;
  border-radius: 4px;
  margin-right: 10px;
}

.payout-requests-tab {
  width: 100%;
  display: flex;
  align-items: left;
  margin: 0px 0px 32px 0px;

  a {
    border-style: none none solid none;
    border-width: 1px;
    background-color: white;
    border-radius: none;
    text-align: left;
    padding: 8px;
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;
  }

  a:hover {
    color: black !important;
  }

  .left {
    width: 30%;
  }

  .right {
    width: 70%;
  }

  .selected {
    border-color: #4078fa;
    color: black;
  }

  .unselected {
    border-color: #c3c3c3;
    color: #c3c3c3 !important;
  }
}

.dropdown-approve {
  color: #1fc881;
}

.dropdown-approve:hover {
  color: #ffffff;
  background-color: #1fc881;
}

.dropdown-reject {
  color: #dc3545;
}

.dropdown-reject:hover {
  color: #ffffff;
  background-color: #dc3545;
}

.dropdown-details {
  color: #007bff;
}

.dropdown-details:hover {
  color: #ffffff;
  background-color: #007bff;
}

.payout-request-header-styling {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
}
