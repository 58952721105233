$black: #000;

$light-blue: #a5c0ff;
$blue: #4078fa;
$primary: $blue;

$green: #1fc881;
$success: $green;

$red: #fd3238;
$danger: $red;

$orange: #ffab00;
$warning: $orange;

$yellow: #ffdf1a;

$white: #fff;

$secondary: #f6f6f6;

$dark-blue: #1a43bb;

$disabled-button-color: #dedede;
$box-shadow-color: #c3c3c3;
$faded-color-dark: #7a7a7a;
$faded-color-light: #c3c3c3;

// Globals
$border-color: #eaeaea;
$border-radius: 4px;
$border-width: 1px;

$border: $border-width solid $border-color;
$border-dashed: $border-width dashed $border-color;

// Fonts
$font-size-base: 0.875rem;
$font-weight-normal: normal;
$font-weight-bold: bold;
$line-height-base: 1.25;
$body-color: #3d3d3d;
$font-family-sans-serif: 'Lato', Sans-Serif;

// Main sidebar
$main-sidebar-collapsed-width: 64px;

// Sidebar
$sidebar-width: 240px;
$sidebar-grey: #f6f6f6;
$sidebar-border: solid $border-width #ededed;
$sidebar-background: linear-gradient(to right, rgba(246, 246, 246, 1) 88%, rgba(240, 240, 240, 1) 100%);

// Typography
$headings-font-weight: $font-weight-bold;
$headings-line-height: 1.2;
$headings-color: $body-color;

$h1-font-size: 40px;
$h1-font-weight: $font-weight-bold;
$h1-margin-bottom: 48px;
$h1-line-height: 1.2;

$h2-font-size: 28px;
$h2-font-weight: 300;
$h2-margin-bottom: 16px;
$h2-line-height: 1.2;

$h3-font-size: 23px;
$h3-font-weight: $font-weight-bold;
$h3-margin-bottom: 25px;
$h3-line-height: 1.2;
$h3-margin-left: -5px;

$h4-font-size: 20px;
$h4-font-weight: $font-weight-bold;
$h4-margin-bottom: 32px;
$h4-line-height: 1.2;

$h5-font-size: 16px;
$h5-font-weight: $font-weight-normal;
$h5-margin-bottom: 16px;
$h5-line-height: 1.2;

$h6-font-size: 14px;
$h6-font-weight: $font-weight-normal;
$h6-margin-bottom: 16px;
$h6-line-height: 1.2;

$h7-font-size: 12px;
$h7-font-weight: $font-weight-bold;
$h7-margin-bottom: 8px;
$h7-line-height: 1.2;

$h8-font-size: 12px;
$h8-font-weight: $font-weight-normal;
$h8-margin-bottom: 8px;
$h8-line-height: 1.2;

// Tooltips
$tooltip-box-shadow: 0 0 10px 0 $box-shadow-color;
$tooltip-color: $primary;
$tooltip-bg: $white;

// Buttons
$btn-padding-y: 0;
$btn-padding-x: 24px;
$btn-line-height: 44px;

$btn-padding-y-sm: 0;
$btn-padding-x-sm: 24px;
$btn-line-height-sm: 28px;

// Links
$link-color: $primary;
$link-decoration: none;
$link-hover-color: darken($link-color, 15%) !default;
$link-hover-decoration: none;

// Forms
$form-feedback-invalid-color: $warning;
$form-feedback-valid-color: #ced4da;
$form-check-input-gutter: 0;

// Animations
$transition-time: 150ms;
$transition-function: ease-in-out;
@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
@mixin fadeInAnimation {
  animation-name: fadeInAnimation;
  animation-duration: $transition-time;
  animation-timing-function: $transition-function;
}

// Badges
$badge-font-size: 12px;
$badge-padding-y: 0.3em;
$badge-padding-x: 0.8em;
$badge-border-radius: 10px;
$badge-line-height: 1.3;

// Modals
$modal-inner-padding: 0;

// $modal-dialog-margin:               .5rem !default;
// $modal-dialog-margin-y-sm-up:       1.75rem !default;

// $modal-title-line-height:           $line-height-base !default;

// $modal-content-bg:                  $white !default;
// $modal-content-border-color:        rgba($black, .2) !default;
$modal-content-border-width: 0;
$modal-content-border-radius: 4px;
$modal-content-box-shadow-xs: 0 0 10px 0 #3d3d3d;
$modal-content-box-shadow-sm-up: 0 0 10px 0 #3d3d3d;

$modal-backdrop-bg: $black !default;
$modal-backdrop-opacity: 0.3;
// $modal-header-border-color:         $gray-200 !default;
// $modal-footer-border-color:         $modal-header-border-color !default;
// $modal-header-border-width:         $modal-content-border-width !default;
// $modal-footer-border-width:         $modal-header-border-width !default;
// $modal-header-padding:              1rem !default;

// $modal-lg:                          800px !default;
$modal-md: 360px;
// $modal-sm:                          300px !default;

$modal-transition: transform 0.15s ease-out !default;

// Cards
$card-faded-text-color: $faded-color-dark;
$card-box-shadow: 0 0 10px 0 #c3c3c3;

// Popovers
$popover-border-width: 1px;
$popover-border-color: #fff;
$popover-box-shadow: $card-box-shadow;

// HR
$hr-border-color: #f6f6f6;
