.no-hover-row {
  cursor: default !important;
}

.domain-entity-table {
  td {
    vertical-align: middle;
    > * {
      vertical-align: middle;
      min-height: 32px;
    }
  }

  &-hover-link:hover {
    .domain-entity-table-hover-icon {
      opacity: 1;
    }
  }

  &-hover-icon {
    opacity: 0;
    display: inline-block;
    margin-left: 8px;
    transition: all 0.25s;
  }

  &-tooltip-trigger:hover {
    ~ .domain-entity-table-tooltip {
      height: auto;
    }
  }

  &-tooltip {
    box-sizing: border-box;
    height: 0px;
    max-height: 400px;
    position: absolute;
    overflow-y: scroll;

    top: 24px;
    background: #fefefe;
    box-shadow: 0 0 10px 0 rgba(61, 61, 61, 0.3);
    border-radius: 4px;

    transition: all 0.2s;
    z-index: 1;

    &-header {
      background: #f3f3f3;
      padding: 8px 18px 8px 18px;
    }

    &-body {
      padding: 8px 18px 8px 18px;
    }

    &:hover {
      height: auto;
    }
  }

  &-tooltip-icon-wrapper {
    position: relative;
    display: flex;
    align-items: center;
  }
}

.center-content {
  text-align: center;
}

.empty-state {
  display: flex;
  align-items: center;
  > div {
    height: 17px;
    border-radius: 4px;
    display: inline-block;
    margin: 0 4px;
    background: linear-gradient(to right, #e3e3e3, #f8f8f8, #e3e3e3);

    background-size: 200% 200%;
    animation: wave-grey 2s linear infinite, fade-in 0.8s;
  }
}

.fader {
  // animation: fade-in 1.2s;
  // transition: 1.2s all ease-in-out;
  padding-top: 16px;
  padding-bottom: 16px;
}

.fader-lean {
  padding-top: 2px;
  padding-bottom: 2px;
}

.hover-dark-red {
  cursor: pointer;
  color: #fd3238;
  &:hover {
    text-decoration: underline;
  }
}

.hover-dark-green {
  cursor: pointer;
  color: #1fc881;
  &:hover {
    text-decoration: underline;
  }
}

.hover-highlight-rows {
  tr:hover {
    transition: all 0.1s;
    background: #f6f6f6 !important;
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes wave-grey {
  0% {
    background-position: 200% 0%;
  }
  100% {
    background-position: 0% 0%;
  }
}
