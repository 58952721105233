.event {
  display: flex;
  flex: 0 0 auto;
  min-height: 68px;
  min-width: 318px;

  &-common {
    flex: 0 0 80px;
    text-align: right;
    padding-top: 20px;
  }

  &-date {
    font-size: 10px;
    font-weight: $font-weight-bold;
    margin-bottom: 2px;
  }

  &-agent {
    font-size: 10px;
    color: $box-shadow-color;
  }

  &-icon {
    flex: 0 0 48px;
    padding-left: 16px;
    padding-top: 26px;
    position: relative;

    &-icon {
      width: 16px;
      height: 16px;
      border-radius: 50%;
      border: $border-width solid $box-shadow-color;
      transition: background-color $transition-time $transition-function,
        border-color $transition-time $transition-function;
    }

    &-line-top {
      position: absolute;
      width: $border-width;
      border-left: $border-dashed;
      left: 23px;
      top: 0;
      height: 26px;
      display: none;
    }

    &-line-bottom {
      position: absolute;
      width: $border-width;
      border-left: $border-dashed;
      left: 23px;
      top: 42px;
      height: 75%;
    }
  }

  &-data {
    display: flex;
    flex: 1;
    padding: 8px 8px 8px 0;

    &-content {
      flex: 1;
      display: flex;
      flex-flow: row wrap;
      border-radius: 4px;
      background-color: #f6f6f6;
      align-items: center;
      transition: background-color $transition-time $transition-function,
        box-shadow $transition-time $transition-function;
      position: relative;
      border: $border-width solid transparent;
      padding: 4px 0;

      &-subtitle {
        padding: 0px 10px 0px;
        font-size: 14px;
        color: #7a7a7a;
      }

      &-title {
        padding: 0 10px;
        font-size: 14px;
        font-weight: $font-weight-bold;
      }
    }
  }

  &.selected {
    .event {
      &-data-content {
        background-color: $white;
        border: $border-width solid $primary;
      }

      &-icon-icon {
        background-color: $primary;
        border-color: $primary;
      }
    }
  }

  &:hover:not(.no-hover):not(.selected) {
    cursor: pointer;

    .event {
      &-data-content {
        background-color: $white;
        box-shadow: 0 0 15px 0px $box-shadow-color;

        &::before {
          width: 0;
          height: 0;
          border-top: 8px solid transparent;
          border-bottom: 8px solid transparent;
          content: ' ';

          border-right: 8px solid white;
          display: inline-block;
          position: absolute;
          left: -8px;
        }
      }
    }
  }
}
