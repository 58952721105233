.nav-stacked-settings {
  > div {
    float: none;
    width: 100%;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    min-height: 40px;
    > a {
      color: black;
      line-height: 8px;
      background-color: #f7f9fb;
      display: block;
      position: absolute;
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
      height: 40px;
      padding-top: 15px;
      width: 90%;
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
      .icon-container {
        width: 16px;
        margin-right: 20px;
        margin-top: -4px;
        margin-left: 15px;
        font-size: 16px;
        text-align: center;
        display: inline-block;
      }
      &.active {
        border-left: solid 4px #4078fa;
        border-right: solid 4px #f7f9fb;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        width: 90%;
        .icon-container {
          width: 16px;
          margin-right: 20px;
          margin-top: -4px;
          margin-left: 11px;
          font-size: 16px;
          text-align: center;
          display: inline-block;
        }
      }
    }
  }
}

.active-settings-container {
  border-left: solid 4px #4078fa;
  line-height: 8px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.password-rules {
  font-size: 14px;
  padding-top: 20px;
  padding-bottom: 10px;
  padding-left: 40px;
  padding-right: 22px;
}

.center-content-wrapper {
  display: flex;
  flex-direction: column;
  text-align: center;
}
