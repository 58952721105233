$table-border-color: $border-color;
$table-border: $border-width solid $table-border-color;

$table-loading-border-color: #f6f6f6;
$table-loading-border: $border-width solid $table-loading-border-color;

$table-active-color: $primary;
$table-border-active: $border-width solid $table-active-color;

$table-chevron-size: 14px;

$table-font-size: 14px;
$table-header-font-size: 12px;

$table-row-hover-color: #f6f6f6;

table {
  width: 100%;
  margin-bottom: 24px;

  &.loading {
    thead tr th {
      pointer-events: none;
    }

    tbody tr {
      td {
        border-bottom: $table-loading-border !important;
      }
    }
  }

  &:not(.no-hover):not(.loading) {
    tbody {
      tr:hover {
        cursor: pointer;
        background: $table-row-hover-color;
      }
    }
  }

  thead {
    tr {
      th {
        text-transform: uppercase;
        font-size: $table-header-font-size;
        font-weight: $font-weight-bold;
        padding: 8px 4px;

        // &.active {
        //   color: $table-active-color;
        //   border-bottom: $table-border-active;

        //   .chevron {
        //     opacity: 1;
        //   }
        // }
      }
    }
  }

  tbody {
    tr {
      td {
        font-size: $table-font-size;
        padding: 21px 4px;
      }
    }
  }

  td,
  th {
    border-bottom: $table-border;
  }
}

.summary-table-width-thirty {
  width: calc((100% - 40px) / 2);
  display: inline-table;
  margin-bottom: 48px !important;
  margin-right: 10px;

  &.full-width {
    width: 100%;
  }

  & + .summary-table {
    margin-left: 24px;
  }

  &.equal {
    tbody {
      tr {
        td {
          width: 39%;
        }
      }
    }
  }

  &.compressed {
    margin-bottom: 0px !important;
    tbody {
      tr {
        td {
          padding: 8px 4px;
        }
      }
    }
  }

  tbody {
    tr {
      td {
        border-bottom: $table-border;
        padding: 12px 4px;

        &:first-child() {
          font-weight: 600;
          width: 30%;
        }
      }
    }
  }
}

.summary-table {
  width: calc((100% - 24px) / 2);
  display: inline-table;
  margin-bottom: 48px !important;

  &.full-width {
    width: 100%;
  }

  & + .summary-table {
    margin-left: 24px;
  }

  &.equal {
    tbody {
      tr {
        td {
          width: 50%;
        }
      }
    }
  }

  &.compressed {
    margin-bottom: 0px !important;
    tbody {
      tr {
        td {
          padding: 8px 4px;
        }
      }
    }
  }

  tbody {
    tr {
      td {
        border-bottom: $table-border;
        padding: 12px 4px;

        &:first-child() {
          font-weight: 600;
        }
      }
    }
  }
}

.paging-button {
  float: right;
  width: 48px;
  padding-left: 4px;
  padding-right: 4px;
  font-size: 24px;
  font-weight: 700;

  & + & {
    margin-right: 8px;
  }
}

.actions-cell {
  span:hover {
    cursor: pointer;
  }
}

.table-action {
  &:hover {
    span svg g path,
    svg g path {
      fill: #fd3238;
      cursor: pointer;
    }
  }
}

td.error-cell {
  span {
    display: inline-block;
    line-height: 24px;
    height: 24px;
    vertical-align: top;

    &:first-child {
      margin-right: 8px;
    }
  }
}

.table-container {
  overflow-x: auto;
  flex: 1;
  max-width: calc(100vw - 240px - 64px);

  table.long {
    min-width: 1180px;
  }
}

@import 'table-header';
@import 'table-chevron';
@import 'table-badge';
@import 'table-cell-multiple';
@import 'table-pagination';
