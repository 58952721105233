.docs {
  font-size: 16px;

  padding: 0;
  overflow-y: auto;

  @import 'api';
}

.docs-indicator {
  height: auto;
  line-height: 32px;
  background-color: #ffab00;
  text-align: center;
  font-size: 13px;
  z-index: 10000;

  img {
    width: 16px;
    height: 16px;
    margin-right: 8px;
    margin-top: -3px;
  }

  p {
    margin-top: 14px;
  }

  a {
    margin-left: 8px;
    font-weight: $font-weight-bold;
  }

  & + .main-layout {
    .overlay-form {
      top: 32px;
    }
  }

  &--full {
    height: 100vh;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    background: none;
    z-index: 0;
    max-width: 1300px;
    margin-left: auto;
    margin-right: auto;

    div {
      font-size: 2em;
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 50px;
      max-width: 600px;
    }

    p {
      font-weight: 300;
    }

    button {
      margin-right: 5px;
      font-size: 1em;
    }
  }
}

body.safari {
  -webkit-font-smoothing: antialiased;
}
