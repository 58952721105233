.environment-section {
  padding-bottom: 50px;

  .info {
    font-size: 11px;
    color: $faded-color-dark;

    span {
      margin-left: 5px;
      line-height: 20px;
      vertical-align: middle;
    }
  }

  button {
    margin-top: 20px;
    margin-bottom: 20px;
    min-width: 124px;
  }
}
