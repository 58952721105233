@import './styles/handle-bars.scss';
@import './views/template-editor/template-editor.scss';
@import './views/modals/merge-vars/communications-merge-vars.scss';

.communication-section .custom-comms-table {
  table tbody tr td {
    font-size: 13px;
  }
  table tbody tr td:first-of-type {
    width: 15%;
  }
  table tbody tr td:nth-of-type(2) {
    max-width: 17%;
  }
  table tbody tr td:nth-of-type(3) {
    width: 34%;
  }
  table tbody tr td:nth-of-type(4) {
    width: 32%;
  }

  &.empty {
    table thead tr th {
      width: 34%;
    }
  }
}

.notification-template-row-cell-enabled {
  color: #aeaeae;
}

.communication-template-badge {
  background: #dedede;
  margin-left: 20px;
  border-radius: 4px;
  color: #3d3d3d;

  &.no-margin-left {
    margin-left: 0px;
  }
}

.communication-table-badge {
  width: 35px;
  height: 16px;
  font-size: 10px;
  line-height: 1em;
  margin-right: 6px;
}

.flex-center {
  display: flex;
  align-items: center;
}

.display-right-flex {
  margin-left: auto;
  padding-right: 10px;
}

.tool-tip-button-styling {
  padding: 5px;
  text-align: left;
  margin-left: 10px;
  margin-right: 10px;
  cursor: pointer;
  word-wrap: normal;

  &:hover {
    background: #f6f6f6;
    border-radius: 4px;
  }

  &.disabled {
    color: #6c757d;
    cursor: not-allowed;
  }

  &.danger {
    color: #fd3238;
  }
}

.tool-tip-divider-styling {
  margin-top: 5px;
  margin-bottom: 5px;
}

.tool-tip-wrapper {
  padding: 0px;
  background: #ffffff;
  margin-top: 0px;
  white-space: nowrap;
  border-radius: 4px !important;
  margin: 0px;
}

.tool-tip-wrapper-content {
  border-radius: 4px !important;
  background: #ffffff;
  cursor: pointer;
  white-space: nowrap;
  padding-top: 5px;
  padding-bottom: 5px;
}

.communications-overlay-header {
  padding-top: 3px;
  padding-bottom: 3px;
  padding-right: 16px;
  height: 54px;
  display: flex;
  align-items: center;
  background: white;

  &.drop-shadow {
    box-shadow: 0 -12px 23px rgba(0, 0, 0, 0.4) !important;
    z-index: 2;
  }

  &.secondary {
    height: 36px;
    margin-left: -16px;
  }

  &.background-dark {
    background: #f4f4f4;
  }

  &.underline {
    border-bottom: 1px solid #c3c3c3;
  }

  &.hover {
    :hover {
      cursor: pointer;
    }
  }
}

.communications-lean-button {
  height: 36px;
  line-height: 28px;
}

.communications-form-overlay-header {
  font-size: 16px;
  line-height: 20px;
  color: #3d3d3d;
  font-weight: bold;
  padding-left: 24px;
}

.communications-overlay-footer {
  padding-top: 3px !important;
  padding-bottom: 3px !important;
  padding-right: 16px;
  height: 54px;
  display: flex;
  align-items: center !important;
  position: absolute;
  bottom: 0px;
  width: 100%;
  background: white;
  box-shadow: 0 19px 38px rgba(0, 0, 0, 0.4);
}

.communications-template-body {
  display: flex;
  flex-direction: row;
  height: 100%;
}

.communications-template-body-inner {
  flex: 1;
  width: 100%;
  background: #f4f4f4;

  &.center {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.communications-spread-button {
  border: 1px solid #c3c3c3;
  color: #c3c3c3;
  padding-left: 15px;
  padding-right: 15px;
  height: 32px;

  &.display-flex {
    display: flex;
  }
}

.notification-event-details {
  padding: 5px 0px 5px 0px;

  > span:first-of-type {
    color: rgba(77, 77, 77, 0.755);
    font-weight: 500;
    margin-bottom: 5px;
  }

  > span {
    float: left;
    clear: left;
    font-size: 13px;

    &.custom-event-key {
      padding: 1px;
      font-size: 11px;
      margin-left: 3px;
    }
  }
}

.spinner {
  animation: rotate 2s linear infinite;
  z-index: 2;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -25px 0 0 -25px;
  width: 50px;
  height: 50px;

  & .path {
    stroke: hsl(210, 70, 75);
    stroke-linecap: round;
    animation: dash 1.5s ease-in-out infinite;
  }
}

// SVG styles.
.communication-svg-saving {
  animation: 2s linear infinite svg-animation-A;
  max-width: 100px;
}

// SVG animation.
@keyframes svg-animation-A {
  0% {
    transform: rotateZ(0deg);
  }
  100% {
    transform: rotateZ(360deg);
  }
}

// Circle styles.
.circle-loader {
  animation: 1.4s ease-in-out infinite both circle-animation-A;
  display: block;
  fill: transparent;
  stroke: white;
  stroke-linecap: round;
  stroke-dasharray: 283;
  stroke-dashoffset: 280;
  stroke-width: 10px;
  transform-origin: 50% 50%;
}

// Circle animation.
@keyframes circle-animation-A {
  0%,
  25% {
    stroke-dashoffset: 280;
    transform: rotate(0);
  }

  50%,
  75% {
    stroke-dashoffset: 75;
    transform: rotate(45deg);
  }

  100% {
    stroke-dashoffset: 280;
    transform: rotate(360deg);
  }
}

.preview-header {
  margin-left: 16px;
  height: 20px;
  width: 57px;
  color: #3d3d3d;
  font-family: Lato;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 20px;
}

.template-view-button {
  box-sizing: border-box;
  height: 32px;
  width: 40px;
  border-radius: 4px 0 0 4px;
  margin: 0px;
  padding-right: 4px;
  padding-left: 7px;
  padding-top: 4px;
  padding-bottom: 4px;
  border: 1px solid #dedede;
  cursor: pointer;

  &.left {
    border-radius: 4px 0 0 4px;
  }

  &.right {
    border-radius: 0 4px 4px 0;
    border-left: none;
  }
}

.phone-outer {
  border-radius: 40px;
  background: white;
  max-width: 370px;
  margin-left: auto;
  margin-right: auto;
  padding: 24px 24px 0;
  height: calc(100% - 147px);
  max-height: 1000px;
  box-shadow: 0 0 10px 0 rgba(61, 61, 61, 0.3);

  .phone-decoration {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 24px;
    display: flex;
    justify-content: center;
    align-items: center;

    .phone-speaker {
      background: #d8d8d8;
      height: 6px;
      border-radius: 3px;
      width: 48px;
    }

    .phone-light {
      background: #d8d8d8;
      height: 10px;
      width: 10px;
      border-radius: 5px;
      margin-left: 8px;
    }
  }

  .phone-screen {
    padding: 2px;
    background-color: #dedede;
    height: calc(100% - 70px);
    border-radius: 4px;
    overflow: auto;

    .phone-text {
      padding: 24px;
      border-radius: 4px;
      background: #c3c3c3;
    }
  }
}

.template-preview {
  z-index: 999;
  height: calc(100% - 90px);
  background: #f4f4f4;
}

.email-config-details-display {
  padding-left: 16px !important;
  margin-right: 16px;
  border-top: 1px solid #c3c3c3;
  position: absolute;
  bottom: 54px;
  width: 100%;
  background: white;

  .is-open {
    bottom: 54px;
  }
}

.template-badge {
  display: flex;
}

.email-details-checkbox {
  margin-bottom: 0px !important;
  font-weight: bold;
}

.email-details-form-group {
  margin-bottom: 0px;
}

.email-details-columns {
  padding-left: 0px;
  padding-top: 8px;
}

.email-details-form-group {
  margin-bottom: 0px;
}

.email-details-label {
  font-weight: bold;
}

.email-attachments-label {
  font-weight: bold;
  display: flex;
  flex-direction: row;

  & > label {
    width: 100%;
    line-height: 2.5;
  }

  & > div {
    width: 100%;
    font-weight: normal;
  }
}

.communications-collapse-icon {
  position: fixed;
  width: 50%;
  padding-right: 14px;

  img {
    float: right;
  }
}

.communications-img-rotate {
  transform: rotate(180deg);
  transition: transform 0.5s;
}

.email-details-collapse {
  width: 50%;
  padding-right: 14px;
}

.email-details-collapsed-row {
  display: flex;
  align-items: center;
  justify-content: center;
}

.communications-modal-header {
  text-align: center;
  margin-top: 20px;
}

.communications-division-border {
  border-top: 1px solid #7a7a7a;
}

.communications-search-bar {
  line-height: 22px !important;
  font-size: 14px !important;
}

.communication-template-tooltip-wrapper {
  padding-left: 0px !important;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  cursor: pointer;
  width: 300px !important;
}

.popover {
  max-width: 400px;
}

.communications-discard-button {
  margin-left: auto;

  &.enabled {
    color: #4078fa;
  }
}

.communications-hover {
  cursor: pointer;
  margin-right: 15px;

  &.padded {
    padding-left: 10px !important;
  }
}

.communication-section-header {
  display: flex;

  + button {
    float: right;
    margin: -30px 0px 15px 0px;
    padding: 0px 20px;
  }
}

.communication-section-header:first-of-type {
  margin-bottom: -15px;
}

.custom-event-key {
  text-align: left;
  padding-left: 4px;
  padding-right: 4px;
  line-height: 1.5;
  font-size: 10px;
  font-family: 'Source Code Pro', monospace;
  color: #4078fa;
  background-color: #f6f6f6;
  border: 1px solid #dedede;
  border-radius: 4px;
  vertical-align: middle;
  word-break: break-all;
}

.communications-event-row-display-wrapper {
  display: flex;
  align-items: center;
}

.communications-template-section-header-main {
  font-size: 17px;
  color: #3d3d3d;
  font-weight: bold;
  margin-bottom: 0px;
}

.communications-template-section-header {
  font-size: 14px;
  color: #3d3d3d;
  font-weight: bold;
  margin-bottom: -5px;
  line-height: 2;
  margin-top: 8px;
}

.communications-modal-paragraph {
  margin-bottom: 40px;
  text-align: center;
}

.add-btn-custom {
  float: right;
  display: flex;
  border-color: #1a43bb;
  border: 1px solid #0606061f;
  border-radius: 5px;
  height: 35px;
  margin-top: -10px;

  span {
    margin: 10px 2px 0 -5px;
    position: relative;
    bottom: 6px;

    & > svg {
      margin-top: 2px;
    }
  }

  button,
  button:hover,
  button:focus {
    margin: 0 5px 0 0;
    padding: 0 3px;
    border: none;
    box-shadow: none;
  }
}

.add-btn-custom:hover {
  border: 1px solid #0606062a;
}

.tooltip.show {
  opacity: 1;
}

.search-bar-loading-state {
  width: 100%;
  height: 30px !important;
}

.communications-spread-button-span {
  margin-top: -2px;
}

.communications-header-badge-wrapper {
  margin-top: 18px;
  margin-left: 10px;
}
