.api-key-created {
  button,
  .primary-block {
    display: inline-block;
  }

  button {
    height: 32px;
    line-height: 28px;
  }

  .primary-block {
    margin-left: 16px;
    img {
      margin-right: 8px;
    }
  }
}

.api-key-env {
  color: #7a7a7a;
  font-weight: normal !important;
}

.api-key-permission-group {
  border-bottom: $border;
  margin-bottom: 24px;
  padding-bottom: 24px;

  &:last-of-type {
    border-bottom: none;
  }

  small {
    color: $body-color;
    font-size: 14px;
  }
}
