.beneficary-row {
  display: flex;
  margin-bottom: 16px;

  &-name,
  &-button {
    flex: 1;

    border-bottom: $border;
    padding-bottom: 16px;
    padding-left: 8px;

    div {
      &:first-child {
        font-weight: $font-weight-bold;
      }

      &:last-child {
        margin-top: 2px;
        color: #dedede;
        font-weight: $font-weight-light;
      }
    }
  }

  &-button {
    border-bottom: 0;
  }

  &-actions {
    flex: 0 0 100px;
    text-align: right;
    border-bottom: $border;
    padding-right: 16px;
    line-height: 36px;
    display: flex;

    .btn,
    span {
      height: 36px;
    }

    span {
      position: relative;
      top: -7px;
      margin-left: 24px;
      cursor: pointer;
    }
  }

  &-total {
    flex: 0 0 100px;
    text-align: right;
    border-bottom: $border;
    padding-right: 40px;
    line-height: 36px;
    height: 48px;
    padding-top: 4px;
  }

  &-total-percentage {
    flex: 0 0 80px;
    border-bottom: $border;
    color: $success;
    font-weight: bold;
    text-align: right;
    line-height: 36px;
    height: 48px;
    padding-right: 8px;
    font-size: 20px;

    &.invalid {
      color: $warning;
    }
  }

  &-percentage {
    flex: 0 0 80px;
    margin-left: 32px;
    border-bottom: $border-dashed;
    text-align: right;
    line-height: 36px;
    position: relative;
    padding-right: 8px;

    input {
      text-align: right;
      border-color: $white !important;
      padding-right: 16px;

      &::-webkit-inner-spin-button,
      &::-webkit-outer-spin-button {
        -webkit-appearance: none;
        appearance: none;
        margin: 0;
      }

      &:focus {
        border-color: $white !important;
      }

      &::placeholder {
        color: $warning;
      }
    }

    .sign {
      position: absolute;
      top: 0;
      right: 10px;
    }

    input,
    .sign {
      &.invalid {
        border-color: $warning;
        color: $warning;
      }
    }
  }
}

.beneficiaries-form {
  h4:first-child {
    margin-bottom: 32px;
  }

  h4:last-child {
    margin-bottom: 16px;
  }

  small {

    display: flex;
    align-items: center;

    span {
      position: relative;
      margin-right: 8px;
    }
  }

  &-list.form-active {
    pointer-events: none;
    opacity: 0.5;
  }
}

.empty-table-action {
  margin-right: 17px;
  width: 54px;
}
