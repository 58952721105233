@import '../beneficiaries/beneficiaries.scss';
@import '../documents/documents.scss';
@import './activity-bar/activity-bar.scss';
@import './cards/entity-cards.scss';
@import '../quotes/quotes.scss';
@import './sandbox/sandbox.scss';

.email-modal {
  width: fit-content;
  max-width: unset !important;
  min-width: 800px;
  height: 90vh;

  .modal-content {
    height: 100%;
  }
}

.sms-modal {
  .modal-body {
    margin-bottom: 32px;
  }

  &-content {
    padding: 16px;
    background-color: #f6f6f6;
  }
}
