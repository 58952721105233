.table-header {
  display: flex;
  margin-bottom: 32px;

  &.no-margin-bottom {
    margin-bottom: 0px;
  }

  .no-padding {
    margin-bottom: 0px;
  }

  h2 {
    margin-bottom: 0;
    margin-top: (48px - $h2-font-size) / 2;
  }

  &-left {
    flex: 1;
  }

  &-right {
    flex: 0 0 auto;
    padding-top: 1px;
  }
  &-right-lean {
    flex: 0 0 auto;
  }
}
