.review {
  display: flex;
  padding: 0;

  &-confimation-text {
    display: flex;
    span {
      &:first-child {
        margin-right: 16px;
      }
    }
  }

  .claim-review {
    background: $primary;
    margin: 12px;
    color: #fff;
    flex: 1;
    border-radius: $border-radius;

    .heading {
      border-bottom: $border-width solid #79a0fb;
      padding: 20px;

      .title {
        font-size: 20px;
        font-weight: $font-weight-bold;
        line-height: 1.4;
        margin-bottom: 8px;
      }

      .role {
        font-size: 12px;
        line-height: 1.5;
        margin-bottom: 4px;
      }

      .claim-review-event {
        font-size: 12px;
        line-height: 1.5;
      }
    }

    .summary {
      padding: 20px;

      .subtitle {
        font-size: 16px;
        font-weight: $font-weight-bold;
        line-height: normal;
        margin-bottom: 8px;
      }

      .outcome {
        font-size: 28px;
        font-weight: 300;
        line-height: 1.14;
        margin-bottom: 24px;
      }
    }

    &.white {
      background-color: $white;
      color: $body-color;

      .heading {
        border-color: #f6f6f6;
      }
    }
  }

  &.no-spacing {
    .claim-review,
    .review-panel {
      margin: 12px 0;

      &:first-child {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        margin-left: 12px;
      }

      &:last-child {
        margin-right: 12px;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    }
  }

  .review-panel {
    flex: 1;
    padding: 20px;
    margin: 12px;

    h4 {
      margin-bottom: 8px;
    }

    & > span {
      font-size: 12px;
      display: block;
      margin-bottom: 32px;
    }

    &.gray {
      background: #f6f6f6;
    }

    &.blue {
      background: $primary;
      color: $white;
      border-radius: $border-radius;

      h4 {
        color: $white;
      }

      h5 {
        font-size: 14px;
        font-weight: bold;
        color: $white;
        margin-bottom: 8px;
      }

      & > p {
        font-size: 14px;

        &.outcome {
          font-size: 28px;
          font-weight: 300;
        }

        margin-bottom: 32px;
      }
    }

    &.green {
      background: $success !important;
      color: $white;
    }

    &.no-spacing {
      &:first-child {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        margin-left: 12px;
      }
      &:last-child {
        margin-right: 12px;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    }

    &.finalize {
      text-align: center;
      padding: 0;
      background-color: #3d3d3d;
      position: relative;

      img {
        width: 240px;
        height: 240px;
        position: absolute;
        top: calc((100% - 240px) / 2);
        left: calc((100% - 240px) / 2);
      }
    }
    > .not-acknowledged-alert {
      span {
        font-size: 12px;
        display: block;
      }
    }
  }
}

.review-agent {
  text-align: center;

  h3 {
    font-size: 20px;
    font-weight: $font-weight-normal;
  }
}

.awaiting-confirmation {
  height: 100%;
  text-align: center;
  position: relative;

  h5 {
    text-align: left;
  }

  img {
    width: 240px;
    height: 120px;
    position: absolute;
    top: 60px;
    left: calc((100% - 240px) / 2);
  }
}

.image {
  height: 50px;
  width: 50px;
}
