@import './data-export-templates/data-export-template.scss';
@import './modals/merge-vars/data-export-template-merge-vars.scss';

.data-export-heading {
  display: flex;
  margin-bottom: 32px !important;

  h2 {
    margin-bottom: 0;
    margin-top: (48px - $h2-font-size) / 2;
  }
}

.data-export-details-card {
  .card-content {
    padding: 0;
    margin: 0;

    .heading {
      padding: 24px;
      background-color: #f6f6f6;
      display: flex;
      justify-content: space-between;

      .title {
        h4 {
          margin-bottom: 0;
          line-height: 32px;
        }

        .paused-badge {
          margin-left: 8px;
        }
      }

      .created-by {
        text-align: right;
      }
    }

    .export-summary,
    .adapter-summary {
      padding: 24px 24px 0px 24px;
    }

    .export-summary-table,
    .adapter-summary-table {
      display: flex;
      justify-content: space-between;

      table {
        width: calc((100% - 24px) / 2);
        display: inline-table;
        margin-bottom: 32px;

        tbody {
          tr {
            td {
              width: 50%;
              border-bottom: $table-border;
              padding: 16px 4px;

              &:first-child() {
                font-weight: 600;
              }
            }
          }
        }
      }
    }
  }
}

.data-export-history {
  td {
    vertical-align: top;
  }

  &-item {
    .status {
      display: flex;
      align-items: center;

      .status-icon {
        padding-right: 8px;

        img {
          width: 16px;
        }
      }
    }
  }

  tr.logs-visible {
    td {
      border-bottom: none;
      padding-bottom: 0;
    }
  }
}

.data-export-run-logs {
  background-color: #f6f6f6;
  border-radius: 4px;
  font-family: 'Consolas', Courier, monospace;
  padding: 16px;

  table {
    margin-bottom: 0;

    tr {
      td {
        border-bottom: none;
        padding: 4px 0;
      }
    }
  }
}

.no-section-divider {
  padding: 0;

  &:not(:last-child) {
    border-bottom: none;
  }
}

.data-export-title-wrapper {
  font-size: 16px;
  font-weight: bold;
}

.data-export-filter-bar-color {
  background-color: #fff;
  border-color: #fff;
}

.data-export-icon-from-status {
  height: 13px;
  margin-right: 5px;
}

.float-right {
  float: right;
}

.handlebars-example-result {
  text-align: left;
  padding-left: 4px;
  padding-right: 4px;
  display: inline;
  font-family: 'Source Code Pro', monospace;
  color: #4078fa;
  background-color: #f6f6f6;
  border: 1px solid #dedede;
  border-radius: 4px;
  vertical-align: middle;
}

.handlebars-example-error {
  vertical-align: middle;
  display: flex;

  p {
    margin: auto auto auto 0px;
    display: inline-block;
  }
  svg {
    margin: auto 8px auto 0px;
    display: inline-block;
  }
}

.data-export-template-editor-merge-vars {
  height: 100% !important;
}

.table-header-data-exports {
  display: flex;
  margin-bottom: 20px;
  margin-top: 10px;

  &.no-margin-bottom {
    margin-bottom: 0px;
  }

  .no-padding {
    margin-bottom: 0px;
  }

  h2 {
    margin-bottom: 0;
    margin-top: 20 / 2;
  }

  &-left {
    flex: 1;
  }

  &-right {
    flex: 0 0 auto;
    padding-top: 1px;
  }
  &-right-lean {
    flex: 0 0 auto;
  }
}

.http-headers-container {
  margin-bottom: 12px;
  display: flex;
  align-items: center;
  & input + input {
    margin-left: 12px;
  }

  & input:first-child {
    width: 35%;
  }
  
}
