$hover-color: #4078fa;
$font-color: black;

.settings-title {
  margin-top: 15px;
  font-weight: bold;
}

.settings-secondary-title {
  font-weight: bold;
  padding-top: 20px;
  padding-bottom: 10px;
  font-size: 13px;
}

.developer-resources > div {
  padding: 5px 0px;

  > a {
    text-decoration: none;
    color: $font-color;
  }
  > a:hover {
    color: $hover-color;
    cursor: pointer;
  }

  img {
    margin: 0px 0px 3px 5px;
  }
}

.timezone {
  .timezone-selector label {
    font-weight: bolder;
  }

  h5 {
    font-weight: bolder;
    font-size: 16px;
  }

  .org-timezones {
    hr {
      margin-top: 9px;
      margin-bottom: 9px;
    }
  }

  .organizations {
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 310px;
  }
}

.popover {
  margin-left: 8px !important;
  width: 224px;

  .popover-body {
    padding: 0px;
    margin-left: -5px;
    width: 230px;
  }
}

.bs-popover-auto .arrow {
  display: none;
}
