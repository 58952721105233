.template-editor-footer-button-wrapper {
  padding: 5px;
  margin-left: auto;
  margin-right: 155px;

  &.no-margin-right {
    margin-right: 0px;
  }
}

.template-editor-header-button {
  margin-left: auto;

  &.primary {
    color: #4078fa;
  }

  &.no-border {
    border: none !important;
  }
}

.template-editor-saved-published-complete {
  background: rgba(61, 61, 61, 0.2);
  z-index: 999;
  position: absolute;
  height: calc(100% - 108px);
  width: 100%;
}

.email-details-card-design {
  margin-bottom: 20px !important;
}

.template-spinner-wrapper {
  height: 80px;
  width: 80px;
  top: calc(50% - 80px);
  left: calc(25% - 40px);
  position: absolute;
  background: rgba(61, 61, 61, 0.5);
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.spinnner-svg {
  margin-top: -25px;
  z-index: 2;
}

.spinner-text {
  margin-top: 30px;
  color: white;
  text-align: center;
  padding-top: 8px;
  padding-bottom: 8px;
}

.merge-vars-select-input {
  width: 250px;
  margin-right: 14px;
  margin-top: -2px;
}

.merge-vars-copied-wrapper {
  padding-right: 10px;
  color: #4078fa;
}

.template-editor-preview-padding {
  height: 5%;
}

.attachment-options {
  margin: auto -10px auto auto;
  width: 50%;

  & div {
    margin-bottom: 0px;
    height: 35px;
  }
}

.template-editor {
  width: 100%;
  height: 100%;
  max-height: 100vh;
  display: flex;
  margin: 0;
  background-color: #f6f6f6;

  .code-editor {
    flex-grow: 1;
    max-width: 50%;
    height: 100%;
    background: rgb(10, 4, 0);

    .CodeMirror {
      cursor: text;
      height: 100%;
      max-height: calc(87vh);
      overflow-y: scroll;

      margin: 0;
    }
  }

  .template-preview {
    flex-grow: 1;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    overflow-y: scroll;
    height: 100%;

    iframe {
      border: none !important;
      outline: none;
      height: 100%;
      width: 100%;
    }
    .template-badge {
      background: pink;
      margin: 8px;
      width: 100px;
      border-radius: 4px;
      box-shadow: 0 0 10px 0 rgba(61, 61, 61, 0.3);
      background-color: #ffffff;
      color: #4078fa;
      float: right;
      cursor: pointer;
    }
    .top-left {
      top: 0;
      left: 0;
    }
    .top-right {
      top: 0;
      right: 0;
    }
    .bottom-left {
      bottom: 0;
      left: 0;
    }
    .bottom-right {
      bottom: 0;
      right: 0;
    }
    .icon-button {
      padding: 16px;
      width: 52px;
      height: 38px;
      display: flex;
      justify-content: center;
      align-items: center;
      &.active {
        background: #f6f6f6;
      }
    }
  }
}

.helper-modal-title-wrapper {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}

.helper-modal-wrapper {
  margin: 16px;
  cursor: default;
}

.slide-in-modal-body-communications {
  height: 100%;

  .CodeMirror {
    height: calc(100vh - 16px - 91px - 86px) !important;
    border-left: 1px solid #dedede;
  }
}

.template-editor-badge {
  background: #dedede;
  margin-left: 20px;
  border-radius: 4px;
  color: #3d3d3d;

  &.no-margin-left {
    margin-left: 0px;
  }
}

.email-config-checkbox-wrapper {
  margin-top: -20px !important;
}
