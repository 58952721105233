.handlebars-helper-modal-btn {
  background-color: #f6f6f6;
  border-color: #f6f6f6;
  text-decoration: none;
  color: #373a3c;
  display: block;
  font-size: 14px;
  padding: 0px 16px 0px 24px;
}

.handlebars-helper-modal-btn:hover {
  background-color: #f6f6f6;
  border-color: #f6f6f6;
  color: #4078fa;
  text-decoration: none;
}

.handle-bars-row-item {
  border-bottom: 1px solid #dedede;
  padding: 22px 0px 22px 0px;
  vertical-align: middle;
  cursor: default;

  div {
    display: flex;
    margin-left: auto;
  }

  button {
    display: flex;
    margin: auto 0px auto auto;
  }

  a {
    padding: 2px;
    vertical-align: middle;
  }

  a.activeChevron {
    svg:hover {
      path {
        fill: #4078fa;
      }
    }
  }

  a.inactiveChevron {
    cursor: default !important;
  }

  textarea {
    width: 90%;
  }

  &.width-70-percent {
    width: 70%;
  }

  &.width-10-percent {
    width: 70%;
  }
}

.handle-bars-table-row-label {
  width: 20%;
  font-weight: bold;
}

.handle-bars-helper-exmaple-row-item {
  width: 70%;
  font-family: monospace;
}
