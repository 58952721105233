@import './full-event';
@import './notes';
@import './events';

$activity-bar-width: 324px;

.activity-bar {
  display: flex;
  flex-direction: column;
  flex: 0 0 $activity-bar-width;
  width: $activity-bar-width;
  min-width: $activity-bar-width;
  border-left: 4px solid #eeeeee;
  transition: all $transition-time $transition-function;
  box-shadow: 0 0 4px 0 rgba($box-shadow-color, 0.3);
  background-color: #fff;

  &-content,
  &-entities,
  &-sort {
    transition: opacity $transition-time $transition-function;
  }

  &.collapsed {
    flex: 0 0 48px;
    width: 48px;
    max-width: 48px;
    min-width: 48px;
    background-color: #f6f6f6;
    cursor: pointer;

    .activity-bar {
      &-content,
      &-entities,
      &-sort {
        opacity: 0;
      }
    }

    > div {
      background: #f6f6f6;
    }
  }

  &-tabs {
    flex: 0 0 54px;
    display: flex;
    background: #f6f6f6;
    padding-top: 16px;

    &-icon {
      flex: 0 0 36px;
      padding: 12px;
      padding-top: 6px;

      &:hover {
        cursor: pointer;
      }
    }

    &-tab {
      flex: 0 0 124px;
      text-align: center;
      font-size: 16px;
      font-weight: $h4-font-weight;
      line-height: 36px;
      transition: all $transition-time $transition-function;
      user-select: none;

      &:not(.active) {
        &:hover {
          color: $primary;
          cursor: pointer;
        }
      }

      span {
        padding-bottom: 12px - $border-width;
        border-bottom: $border-width solid transparent;
        transition: all $transition-time $transition-function;
      }

      &.active {
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        background-color: $white;
        box-shadow: 0 -2px 4px 0 $box-shadow-color;

        span {
          padding-bottom: 12px - $border-width;
          border-bottom: $border-width solid $primary;
        }
      }
    }
  }

  &-entities {
    flex: 0 0 auto;
    border-bottom: solid $border-width $border-color;
    padding: 16px;
    padding-bottom: 8px;
    overflow: hidden;
    user-select: none;
    flex-wrap: wrap;
    max-width: $activity-bar-width;

    &-entity {
      display: inline-block;
      border-radius: 2px;
      text-transform: uppercase;
      font-size: $h8-font-size;
      padding: 4px 8px;
      margin-bottom: 8px;
      transition: all $transition-time $transition-function;

      &.active {
        background: $primary;
        color: $white;
      }

      &:not(.active):not(.caret) {
        &:hover {
          background: #f3f3f3;
          cursor: pointer;
        }
      }

      &:not(.active):last-child {
        border: $border-width solid $primary;
        color: $primary;
        padding: 2px 8px;
      }
    }
  }

  &-sort {
    flex: 0 0 52px;
    line-height: 52px;
    padding-left: 16px;
    border-bottom: solid $border-width $border-color;
    overflow: hidden;
    display: block;
    @include fadeInAnimation;

    &.hidden {
      display: none;
    }
  }

  &-content {
    flex: 1;
    flex-direction: column;
    display: flex;
    overflow-x: hidden;
    @include fadeInAnimation;

    &::-webkit-scrollbar {
      width: 0px;
      height: 0px;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background: transparent;
    }

    &.hidden {
      display: none;
    }

    .event:first-child {
      .event-icon {
        .event-icon-line-top {
          display: none;
        }
      }
    }

    .event:last-child {
      .event-icon {
        .event-icon-line-bottom {
          display: none;
        }
      }
    }
  }
}
