.secret-key {
  text-align: left;
  padding: 2px;
  line-height: 1.5;
  font-size: 11px;
  font-family: 'Source Code Pro', monospace;
  color: #4078fa;
  background-color: #f6f6f6;
  border: 1px solid #dedede;
  border-radius: 4px;
  vertical-align: middle;
  word-break: break-all;
}

.section-access-key {
  .entity-access-card {
    padding: '20px';
    box-shadow: 'none';
    border: 1px solid #eaeaea;

    .access-details {
      display: block;
      height: 55px;

      h5 {
        padding: 10px;
        font-weight: bolder;
      }

      hr {
        border: 0.5px solid #eaeaea;
        margin-top: -10px;
      }
    }

    p {
      padding: 10px;
    }
  }

  .access-hover {
    line-height: 1;
    cursor: pointer;
    padding-left: 7px;
    position: relative;
    bottom: 1px;
  }
}

.field-header {
  font-weight: bolder;
}

.data-adapter-modal > .modal-content {
  width: 650px;
  margin: auto;
  left: -100px;
}

.btn-new-secret {
  float: left;
  height: 30px !important;
  line-height: 0;
  padding: 15px;
}

.tooltip-popover {
  background: #ffffff;
  cursor: pointer;
  padding: 12px;
  font-size: 12px;
}

.data-adapter-top {
  display: inline-block;
  height: 8px;
  background: linear-gradient(101.66deg, #2969ff 9.5%, #a5c0ff 92.4%);
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  background-size: 100%;
}

.bolder {
  font-weight: bolder;
}

.form-control.secret-keys {
  margin-bottom: 8px;
  resize: none;
  height: auto;
}

.api-key-created {
  padding-top: 10px;

  #secret-text-area,
  #api-text-area {
    background: #dedede83;
    border: 1px solid #dedede !important;
  }

  button,
  button:hover,
  button:focus {
    border: 1px solid rgba(0, 0, 0, 0.118) !important;
  }
}

.render-empty-state {
  text-align: center;
  background-color: #f8fafc;
  padding: 50px;
  margin: 15px;
  border-radius: 5px;

  h4 {
    margin-bottom: 25px;
  }
  button {
    margin-top: 30px;
  }
}

.data-adapter-modal .modal {
  width: 400px;
}

.data-adapter-content {
  position: relative;
  float: left;
  left: 0;

  .data-adapter.table-header {
    width: 750px;
  }

  .list-empty-state {
    width: 100%;
  }

  .subtitle {
    font-weight: 300;
    color: #00000099;
  }

  hr {
    margin: 10px 0px;
    border: 0.5px solid #eaeaea;
    width: 100%;
  }

  h4 {
    font-size: 18px;
    font-weight: bolder;
  }
}

.data-adapter-modal-header {
  text-align: left;
  padding: 15px;
  margin: -20px 25px 0px 25px;
}

.modal-description {
  margin: 0px 10px;
}

.content {
  justify-content: left;
}

.close-btn {
  text-align: right;
  padding: 10px;
  cursor: pointer;
  margin: 5px 15px 0px 0px;
}
