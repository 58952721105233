.webhook-heading {
  display: flex;
  margin-bottom: 32px !important;

  h2 {
    margin-bottom: 0;
    margin-top: (48px - $h2-font-size) / 2;
  }
}

.webhook-details-card {
  .card-content {
    padding: 0;
    margin: 0;

    .heading {
      padding: 24px;
      background-color: #f6f6f6;
      display: flex;
      justify-content: space-between;

      .title {
        h4 {
          margin-bottom: 0;
          line-height: 32px;
        }

        .paused-badge {
          margin-left: 8px;
        }
      }

      .created-by {
        text-align: right;
      }
    }

    .webhook-summary,
    .webhook-summary {
      padding: 24px 24px 0px 24px;
    }

    .webhook-summary-table,
    .webhook-summary-table {
      display: flex;
      justify-content: space-between;

      table {
        width: calc((100% - 24px) / 2);
        display: inline-table;
        margin-bottom: 32px;

        tbody {
          tr {
            td {
              width: 50%;
              border-bottom: $table-border;
              padding: 16px 4px;

              &:first-child() {
                font-weight: 600;
              }
            }
          }
        }
      }
    }
  }
}
