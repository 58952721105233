.quote-step {
  &-counter {
    margin-top: 24px;
    margin-bottom: 48px;
    color: #c3c3c3;
    font-weight: 300;
    font-size: 16px;
  }

  &-premium {
    font-size: 24px;
    font-weight: 300;
    margin-bottom: 48px;
    margin-top: 32px;

    span {
      font-size: 14px;
      font-weight: 600;
      color: #3d3d3d;
      padding-left: 14px;
    }
  }
}

.quote-module-type-card {
  height: 76px;
  border-radius: 4px;
  border: $border;
  // flex: 0 0 calc((100% - 38px) / 2); NEED TO FIGURE OUT WHY THIS BREAKS SASS
  padding: 27px 24px;
  cursor: pointer;
  background-color: #fff;
  @include transition(
    background-color 0.15s linear,
    border-color 0.15s linear,
    box-shadow 0.15s linear
  );

  &:nth-child(odd) {
    margin-right: 38px;
  }

  &:not(:first-child):not(:nth-child(2)) {
    margin-top: 24px;
  }

  span {
    font-weight: bold;
    font-size: $h5-font-size;
  }

  img {
    float: right;
  }

  &.active {
    border-color: $primary;
    background-color: #f6f6f6;
  }

  &:hover:not(.active) {
    box-shadow: $card-box-shadow;
    border-color: transparent;
  }
}

.quote-module-type-line-break {
  width: 100%;
}

.quote-lead {
  border-radius: 4px;
  margin-left: 8px;
  width: 300px;
  margin: 24px auto !important;

  &::before {
    content: ' ';
    display: block;
    width: 100%;
    height: 4px;
    background: linear-gradient(to right, #1a42ba, #79a0fb);
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    background-size: 400% 100%;
  }

  &__title {
    padding: 20px 30px;
    border-bottom: 2px solid #eaeaea;
    border-radius: 4px;
    cursor: pointer;

    img {
      float: right;
    }
  }

  &__body {
    input {
      margin-bottom: 20px;
    }
    p {
      margin: 20px 0;
    }
  }

  table tbody tr td .transition-text {
    background: #eaeaea;
  }

  table {
    td,
    th {
      width: 200px;
    }

    thead tr {
      display: block;
      position: relative;
    }

    tbody {
      display: block;
      overflow: auto;
      width: 100%;
    }
  }
}

.quote-summary {
  padding: 0 8px;
}

.summary-heading {
  font-size: 24px;
  font-weight: 300;
  line-height: 1.33;
  color: #3d3d3d;
  margin-top: 60px;
  margin-bottom: 20px;
  border-bottom: solid 2px #eaeaea;
  padding-bottom: 10px;
}

.product-module-selector {
  .expandable {
    border: solid 2px #eaeaea;
    margin: 8px 0;
    border-radius: 4px;

    .expandable-heading {
      color: #3d3d3d;
      display: flex;
      padding: 16px;
      align-items: center;
      cursor: pointer;
      h4 {
        margin: 0;
        font-size: 20px;
        line-height: 28px;
      }
      span {
        height: 24px;
        margin-right: 8px;
      }
      button {
        margin-left: auto;
      }
    }

    .expandable-content {
      padding: 0 16px 16px 16px;
      .lead-module-data {
        background-color: #f6f6f6;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 4px;
        padding: 8px 16px;
        border-radius: 4px;
        cursor: pointer;
        &:hover {
          background-color: #eaeaea;
        }
        :nth-child(1) {
          flex: 2;
        }
        :nth-child(2) {
          flex: 4;
        }
        :nth-child(3) {
          flex: 1;
        }
      }
      .lead-module-data-disabled {
        background-color: #f6f6f6;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 4px;
        padding: 8px 16px;
        border-radius: 4px;
        :nth-child(1) {
          flex: 2;
        }
        :nth-child(2) {
          flex: 4;
        }
        :nth-child(3) {
          flex: 1;
        }
      }
      .app-data-set {
        box-sizing: border-box;
        div {
          display: flex;
          strong {
            flex: 2;
          }
          span {
            flex: 3;
          }
        }
      }
    }
  }
}

.policy-summary-list {
  h4 {
    display: flex;
    margin-top: 16px;

    .premium {
      margin-left: auto;

      span {
        margin-left: 8px;
      }
    }
  }
  h4 :first-child {
    margin-right: 8px;
  }

  .policy-summary {
    margin-top: 42px;

    .policy-summary-content {
      border: solid 2px #eaeaea;
      border-radius: 2px;
      table {
        margin-bottom: 24px !important;
      }

      .policy-summary-details {
        padding: 16px;
      }
      .policy-summary-footer {
        display: flex;
        justify-content: flex-end;
        width: 100%;
        background: #f6f6f6;
        min-height: 50px;
      }
      .policy-summary-footer :last-child {
        margin-left: 8px;
        cursor: pointer;

        &:hover {
          span svg g path,
          svg g path {
            fill: #fd3238;
          }
        }
      }
    }
  }
}

.payment-section {
  margin-top: 32px;

  .payment-empty-state {
    min-width: 600px;
    border: solid 2px #eaeaea;
    padding: 32px;
    margin: 24px 0;
  }

  .payment-method-details {
    border: solid 2px #eaeaea;
    margin: 24px 0;
    padding: 16px;
    display: flex;
    justify-content: space-between;

    span {
      font-weight: 600;
    }
  }
}

.declaration-section {
  margin: 48px 0;
  p {
    color: #3d3d3d;
    line-height: 21px;
    font-weight: bold;
    size: 14px;
  }

  ul {
    padding-left: 24px;
  }

  li {
    color: #3d3d3d;
    line-height: 21px;
    size: 14px;
  }

  span {
    margin-left: 8px;
  }
}

.debit-order-details {
  h5 {
    margin-top: 40px;
    font-weight: bold;
  }
  td:first-child {
    font-weight: initial !important;
  }
  tr :last-child {
    text-align: right;
  }
}

.summary-checklist {
  padding: 48px 24px;
  width: 300px;
  margin: auto;
  .checklist-item {
    display: flex;
    align-items: center;

    p {
      margin: 0;
    }

    img {
      margin: 8px 16px 8px 0;
    }
  }
}

.summary-totals {
  border-bottom: solid 2px #eaeaea;
  justify-content: space-between;
  display: flex;
  margin: 0;
  padding: 20px 0;
}

.quote-progress-bar {
  padding: 0 0 40px 0;
  margin-bottom: 0;

  div {
    display: inline-block;

    span {
      padding: 4px 8px;
      font-weight: bold;
      border-radius: 2px;
    }

    img {
      margin: 0 8px;
    }

    .complete {
      color: #4078fa;
    }

    .active {
      background: #4078fa;
      color: white;
    }

    .incomplete {
      color: #c3c3c3;
    }
  }
}

.quote-lead-summary {
  padding: 24px 0;
  width: 300px;
  margin: auto;

  .lead-information {
    margin: 0px 8px;

    .name {
      padding-top: 8px;
      margin-bottom: 0;
    }

    .country {
      height: $h3-font-size + 4px;
      line-height: $h3-font-size + 4px;
      margin-right: 8px;

      & > span svg {
        height: 22px;
      }
    }

    .id-number {
      height: $h3-font-size + 4px;
      line-height: $h3-font-size + 4px;
      display: inline-block;
      margin-bottom: 0;
      color: $card-faded-text-color;
    }
  }

  .app-data {
    width: 100%;

    :last-child {
      margin-bottom: 0 !important;
    }

    .app-data-set {
      box-sizing: border-box;
      padding: 16px;
      background-color: #f6f6f6;
      border-radius: 4px;
      margin-bottom: 8px;
      div {
        display: flex;
        :nth-child(1) {
          flex: 2;
        }
        :nth-child(2) {
          flex: 3;
        }
      }
    }
  }

  .card-content {
    padding: 12px;
    border-bottom: #eaeaea 2px solid;
  }

  .card-content:first-of-type {
    padding-top: 24px;
  }

  .card-content:last-of-type {
    border-bottom: none;
  }
}

.summary-empty-section {
  height: 96px;
  background-color: #f6f6f6;
  margin-bottom: 16px;
}

.summary-inner {
  height: calc(100% - 80px);

  .hide-scroll-bars {
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    padding-right: 17px;
    box-sizing: content-box; /* So the width will be 100% + 17px */
  }
}

.lead-search-result {
  display: flex;
  flex-direction: column;
  background-color: #f6f6f6;
  padding: 16px;
  border-radius: 4px;
  margin: 8px;
  cursor: pointer;

  &:hover {
    background-color: #eaeaea;
  }

  .heading {
    display: flex;
    justify-content: space-between;
    font-weight: bold;
    margin-bottom: 8px;
  }

  .sub-heading {
    font-size: 14px;
    color: #c3c3c3;
    margin-bottom: 16px;
  }

  .content-line {
    margin: 4px 0;
  }
}

.quote-wizard-loading-state {
  height: 100px;
  width: 100%;
  background-color: #f6f6f6;
  border-radius: 5px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.quote-wizard {
  .summary-heading {
    margin-top: 48px;
  }
}

.leads-search-icon {
  height: 48px;
  width: 54px;
  position: absolute;
  z-index: 1;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0 24px 24px 0;
  transition: 0.2s all;
  padding-right: 6px;
  &.active {
    background-color: #4078fa;
    cursor: pointer;
  }
  &.active g {
    stroke: white;
  }
  &.active path {
    stroke: white;
  }
}

.leads-clear-icon {
  width: 20px;
  position: absolute;
  z-index: 2;
  right: 60px;
  top: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.2s all;
  cursor: pointer;
  &.hidden {
    opacity: 0;
    cursor: default;
  }
}

.quote-package-list {
  width: 100%;

  .quote-package {
    border: solid 2px #eaeaea;
    border-radius: 4px;
    margin: 8px;
    display: flex;
    align-items: center;
    width: 100%;
    background: inherit;
    padding: 0 7px;
    cursor: pointer;
    outline: none;
    &.active {
      border-color: #4078fa;
    }

    &.loading {
      background: #f6f6f6;
      border-color: #f6f6f6;
      height: 98px;
    }

    .quote-package-premium {
      flex: 1;
      display: flex;
      align-items: center;
      border-right: solid 2px #eaeaea;
      border-bottom: solid 2px #eaeaea;
      padding: 24px;
      box-sizing: border-box;
      .text {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        color: initial;
        h3 {
          font-size: 24px;
          font-weight: 300;
          line-height: 1.33;
          color: #3d3d3d;
          margin: 0;
        }
        span {
          font-size: 12px;
          font-weight: bold;
          text-align: left;
        }
      }

      svg {
        margin-right: 24px;
      }
    }

    .quote-package-description {
      display: flex;
      flex: 2;
      flex-direction: column;
      align-items: flex-start;
      padding: 24px;
      color: initial;
      border-bottom: solid 2px #eaeaea;
      span {
        text-align: left;
        margin: 4px;
      }
    }

    .quote-package-wrapper {
      margin-left: 20px;
      margin-bottom: -10px;
      margin-top: 25px;
    }
  }
}
