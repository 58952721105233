.full-event {
  padding: 32px;
  border-radius: 4px;
  border: solid $border-width $border-color;
  margin-bottom: 16px;
  transition: border-color $transition-time $transition-function;
  @include fadeInAnimation;

  &.selected {
    border: $border-width solid $primary;
  }

  &-container {
    overflow: hidden;
    flex: 1;
    display: flex;
    flex-direction: column;

    & + .flex-container {
      display: none;
    }
  }

  &-heading {
    padding: 16px 20px 20px;
    flex: 0 0 auto;
    box-shadow: 0 4px 8px 0 rgba(#fff, 0.75);
    display: flex;
    z-index: 2;

    h2,
    a {
      margin-top: (48px - $h2-font-size) / 2;
      margin-bottom: 0;
    }

    a {
      line-height: $h2-font-size;

      img {
        width: 16px;
        height: 16px;
        margin-top: -2px;
        margin-left: 4px;
      }
    }
  }

  &-display {
    overflow-y: scroll;
    overflow-x: hidden;
    margin-right: -15px;
    z-index: 0;
    padding: 16px 20px 20px;
  }

  &-header {
    display: flex;
    margin-bottom: 16px;
  }

  &-common {
    flex: 0 0 auto;
  }

  &-title {
    flex: 1;
    font-size: $h4-font-size;
    font-weight: $h4-font-weight;
  }

  &-subtitle {
    flex: 1;
    font-size: $h8-font-size;
    font-weight: $h8-font-weight;
    color: $faded-color-dark;
    padding-top: 4px;
  }

  &-date {
    font-size: $h8-font-size;
    font-weight: $h8-font-weight;
    margin-bottom: 2px;

    span {
      font-size: $h7-font-size;
      font-weight: bold;
    }
  }

  &-agent {
    font-size: $h8-font-size;
    font-weight: $h8-font-weight;
  }

  &-content {
    .event-content-line {
      line-height: 1.5;
      display: flex;
      flex-direction: row;
      align-items: center;

      span {
        display: inline-flex;
        flex-direction: row;
        align-items: center;
        white-space: pre-wrap;
      }

      img {
        margin-left: 0.2rem;
      }
    }

    .event-content-grey {
      color: $faded-color-dark;
    }
  }
}

.full-event-display {
  overflow-y: auto;
  margin-right: 0 !important;

  &::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: transparent;
  }
}
