$gray-light: #e1ebf0 !default;

$docs-top-spacer: 32px;
$docs-sidebar-bg: #f6f6f6;
$docs-sidebar-border-color: $gray-light;
$docs-sidebar-padding: 24px;
$docs-sidebar-heading-color: #afafaf;
$docs-sidebar-heading-hover-color: #989898;

$docs-header-sidebar-padding: 12px 18px;
$docs-header-languages-bg: #171c19;

$docs-separator-copy-color: #f0f0f0;
$docs-separator-examples-color: #2a3344;

$docs-content-bg: white;
$docs-content-color: #596981;

$docs-list-table-font-size: 13px;

$docs-copy-heading-color: #202b40;
$docs-copy-padding: 18px 24px 24px 24px;
$docs-copy-list-heading-color: #7a7a7a;
$docs-copy-list-bg: $docs-sidebar-bg;
$docs-copy-list-attributes-bg: #ececec;
$docs-copy-list-border: 1px solid $docs-copy-list-bg;
$docs-copy-list-border-radius: 5px;
$docs-copy-list-inside-border-color: white;
$docs-copy-list-detail-color: #7a7a7a;

$docs-examples-bg: #2d2d2d;
$docs-examples-color: #d7dce0;
$docs-examples-heading-color: $docs-examples-color;
$docs-examples-padding: $docs-copy-padding;
$docs-examples-list-bg: rgba(255, 255, 255, 0.08);
$docs-examples-list-border-radius: 5px;
$docs-examples-list-inside-border-color: $docs-examples-bg;
$docs-examples-list-label-color: white;
$docs-examples-list-detail-color: $docs-examples-color;

$docs-example-padding: 12px;
$docs-example-border-radius: 5px;
$docs-example-bg: $docs-header-languages-bg;

$h1-font-size-new: 40px;
$h1-font-weight-new: bold;
$h1-margin-bottom-new: 48px;
$h1-line-height-new: 1.2;

$h2-font-size-new: 28px;
$h2-font-weight-new: 300;
$h2-margin-bottom-new: 40px;
$h2-line-height-new: 1.2;

$h3-font-size-new: 24px;
$h3-font-weight-new: bold;
$h3-margin-bottom-new: 32px;
$h3-line-height-new: 1.2;

$h4-font-size-new: 20px;
$h4-font-weight-new: normal;
$h4-margin-bottom-new: 32px;
$h4-line-height-new: 1.2;

$h5-font-size-new: 16px;
$h5-font-weight-new: normal;
$h5-margin-bottom-new: 16px;
$h5-line-height-new: 1.2;

$h6-font-size-new: 14px;
$h6-font-weight-new: normal;
$h6-margin-bottom-new: 16px;
$h6-line-height-new: 1.2;

.documentation {
  ::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  ::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0.1);
    border-radius: $border-radius;
  }

  ::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.3);
    border-radius: $border-radius;
  }

  .header {
    z-index: 4;
    position: absolute;
    top: $docs-top-spacer;
    left: 251px;
    right: 0;

    .header-sidebar {
      position: absolute;
      top: 0;
      left: -251px;
      width: 250px;
      border-bottom: 1px solid $docs-sidebar-border-color;
      background-color: $docs-sidebar-bg;
      padding: $docs-header-sidebar-padding;
      display: flex;
      align-items: center;
      height: 76px;
      border-bottom: 2px solid #eaeaea;

      svg {
        fill: #202b40;

        .logo-icon {
          fill: $primary;
        }
      }

      .link {
        display: inline-block;
        cursor: pointer;

        &.product {
          position: relative;
          top: -1px;

          img {
            margin-left: 4px;
            margin-top: -10px;
            width: 16px;
            height: 16px;
          }
        }

        &:first-child {
          display: block;
          height: 28px;

          a:hover {
            opacity: 0.7;
          }
        }

        &:nth-child(2) {
          font-size: 20px;
          font-weight: 500;
          margin-left: 12px;

          a {
            color: #0f1b4a;
          }

          a:hover {
            text-decoration: none;
            color: rgba($primary, 0.7);
          }
        }
      }
    }

    .header-language {
      position: absolute;
      top: 0;
      left: 45%;
      right: 0;

      ul {
        list-style: none;
        display: flex;
        margin: 0;
        padding: 15px 0 16px 24px;
        background: $docs-header-languages-bg;

        li {
          display: block;
          padding: 6px 13px;
          margin: 0 2px;
          border: 1px solid transparent;
          border-radius: 5px;
          color: #4078fa;

          &:hover {
            color: #79a0fb;
            cursor: pointer;
            user-select: none;
          }

          &.active {
            background-color: $primary;
            border-color: $primary;
            color: white;
          }
        }
      }
    }
  }

  .sidebar {
    width: 250px;
    position: absolute;
    top: $docs-top-spacer + 76px;
    bottom: 0;
    left: 0;
    z-index: 2;
    background: $docs-sidebar-bg;
    display: flex;
    flex-direction: column;
    padding: 0;
    border-right-width: 0;

    &-header-container {
      flex: 0 0 auto;
      padding: 0 $docs-sidebar-padding;
      margin-top: 24px;
      margin-bottom: 32px;
    }

    &-footer-container {
      flex: 0 0 auto;
      padding: 0 $docs-sidebar-padding;
      text-transform: uppercase;
    }

    &-header {
      background: $primary;
      color: #fff;
      font-size: 14px;
      padding: 4px 8px;
      position: relative;
      left: -4px;
      border-radius: 4px;
      line-height: 1.29;
    }

    &-footer {
      font-size: 12px;
      font-weight: bold;
      line-height: 1.5;
      color: #3d3d3d;
      padding-bottom: 16px;
      padding-top: 12px;
      margin-top: 4px;
      display: block;
      border-top: 2px solid $border-color;
      text-transform: uppercase;

      span {
        float: right;
        border: 1px solid $primary;
        color: $primary;
        padding: 2px 4px;
        font-size: 8px;
        font-weight: normal;
        border-radius: 2px;
        text-transform: uppercase;
      }

      &:hover {
        cursor: pointer;
      }
    }

    .sidebar-inner {
      overflow-y: auto;
      overflow-x: hidden;
      padding: 0 $docs-sidebar-padding;
      flex: 1;

      button.sidebar-category-title {
        text-transform: uppercase;
        font-size: 12px;
        font-weight: bold;
        line-height: 1.5;
        color: $docs-sidebar-heading-color;
        padding: 6px 0;
        display: block;

        cursor: pointer;

        &:hover {
          color: $docs-sidebar-heading-hover-color;
        }
      }

      ul {
        font-size: 14px;
        list-style: none;
        padding: 0;
        margin-bottom: 12px;

        &.collapsed {
          display: none;
        }

        li {
          a {
            display: block;
            color: inherit;

            &.active {
              color: $primary;
              font-weight: bold;
              background: none;
            }

            &:hover {
              background: #e9e9e9;
            }
          }
        }
      }
    }
  }

  .background {
    top: $docs-top-spacer;
    bottom: 0;
    left: 250px;
    right: 0;
    position: fixed;
    z-index: 1;
    background: $docs-content-bg;

    .background-inner {
      top: 0;
      bottom: 0;
      right: 0;
      left: 45%;
      position: absolute;
      background: $docs-examples-bg;
    }
  }

  .api-content {
    position: absolute;
    top: $docs-top-spacer;
    bottom: 0;
    left: 250px;
    right: 0;
    z-index: 3;
    overflow-x: hidden;
    overflow-y: scroll;
    padding-bottom: 400px;

    p {
      margin-bottom: 12px;
      font-size: 13px;
      line-height: 1.43;
      padding-right: 10px;
    }

    section {
      .section-title {
        font-size: 12px;
        color: #3d3d3d;
        text-transform: uppercase;
        margin-bottom: 12px;
        padding-top: 22px;
      }

      .component {
        margin-bottom: 40px;
      }

      .markdown {
        img {
          max-width: 100%;
        }

        ul {
          li {
            padding-bottom: 3px;
            font-size: 14px;
            line-height: 1.43;
          }
        }

        ol {
          li {
            padding-bottom: 3px;
            font-size: 14px;
            line-height: 1.43;
          }
        }

        p {
          img {
            width: 100%;
            max-width: 552px;
            margin: 0 auto;
            display: block;
          }
        }
      }

      .markdown > p > code,
      .list code {
        border-radius: 2px;
        background-color: #dbebff;
        padding: 2px 5px;
      }

      .list {
        .list-heading {
          font-size: inherit;
          text-transform: uppercase;
          font-weight: 300;
          color: #7a7a7a;
        }

        .list-table {
          list-style: none;
          margin: 0;
          padding: 0;
          font-size: $docs-list-table-font-size;

          .item {
            display: block;
            padding: 8px 0;

            .item-label {
              position: relative;
              float: left;
              text-align: right;
              font-size: inherit;
              font-weight: bold;
              word-break: break-all;
              white-space: normal;
              width: 160px;
              line-height: inherit;
              margin: 0;

              .item-label-prefix {
                color: $docs-copy-list-detail-color;
              }

              .item-label-detail {
                display: block;
                font-weight: 300;
                font-size: 1em;
                color: $docs-copy-list-detail-color;
              }
            }

            .item-description {
              position: relative;
              margin-left: 175px;

              p:last-child {
                margin-bottom: 0;
              }
            }

            .item-attributes {
              button {
                margin: 0.5em 0 0 175px;
                font-size: 1em;
              }
              .list-table {
                margin: 1em;
                background: $docs-copy-list-attributes-bg;
              }
            }

            &:after {
              content: '';
              clear: both;
              display: block;
            }
          }

          .item-empty-message {
            padding: 10px;
            font-style: italic;
            text-align: center;
          }
        }

        &:after {
          content: '';
          clear: both;
          display: block;
        }
      }

      .copy {
        h1 {
          font-size: $h1-font-size-new !important;
          font-weight: $h1-font-weight-new;
          margin-bottom: $h1-margin-bottom-new;
          line-height: $h1-line-height-new;
        }

        h2 {
          font-size: $h2-font-size-new !important;
          font-weight: $h2-font-weight-new;
          margin-bottom: $h2-margin-bottom-new;
          line-height: $h2-line-height-new;
        }

        h3 {
          font-size: $h3-font-size-new !important;
          font-weight: 300;
          margin-bottom: $h3-margin-bottom-new;
          line-height: $h3-line-height-new;
        }

        h4 {
          margin-top: 20px;
          font-size: $h4-font-size-new !important;
          font-weight: $h4-font-weight-new;
          margin-bottom: $h4-margin-bottom-new;
          line-height: $h4-line-height-new;
        }

        h5 {
          font-size: $h5-font-size-new !important;
          font-weight: $h5-font-weight-new;
          margin-bottom: $h5-margin-bottom-new;
          line-height: $h5-line-height-new;
        }

        h6 {
          font-size: $h6-font-size-new !important;
          font-weight: $h6-font-weight-new;
          margin-bottom: $h6-margin-bottom-new;
          line-height: $h6-line-height-new;
        }

        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
          color: $docs-copy-heading-color;
        }

        width: 100%;
        max-width: 45%;
        float: left;
        border-top: 1px solid $docs-separator-copy-color;

        .copy-inner {
          padding: $docs-copy-padding;
        }

        .list {
          h3 {
            font-size: $docs-list-table-font-size !important;
            margin-bottom: 12px;
            margin-top: 32px;
          }

          h4 {
            font-size: $docs-list-table-font-size !important;
            margin-bottom: 12px;
            margin-top: 32px;
          }

          .list-heading {
            color: $docs-copy-list-heading-color;
          }

          .list-table {
            background: $docs-copy-list-bg;
            border-radius: $docs-copy-list-border-radius;

            .item {
              font-size: $docs-list-table-font-size;
              border-top: 1px solid $docs-copy-list-inside-border-color;

              &:first-child {
                border: none;
              }
            }
          }
        }
      }

      .examples {
        position: relative;
        // position: absolute;
        margin-left: 45%;
        // width: 55%;
        border-top: 1px solid transparent;
        color: $docs-examples-color;
        padding-top: 67px; // copy's h1 total height minus this h3's margin-top

        .examples-inner {
          padding: $docs-examples-padding;

          h1,
          h2,
          h3,
          h4,
          h5,
          h6 {
            color: $docs-examples-heading-color;
          }

          p {
            font-weight: 300;
          }

          .example {
            &.definition:before {
              content: 'DEFINITION';
              display: block;
              padding-bottom: 8px;
              font-size: 12px;
              color: #dedede;
            }

            &.request:before {
              content: 'EXAMPLE REQUEST';
              display: block;
              padding-bottom: 8px;
              font-size: 12px;
              color: #dedede;
            }

            &.response:before {
              content: 'EXAMPLE RESPONSE';
              display: block;
              padding-bottom: 8px;
              font-size: 12px;
              color: #dedede;
            }

            &.endpoint:before {
              content: 'API ENDPOINT';
              display: block;
              padding-bottom: 8px;
              font-size: 12px;
              color: #dedede;
            }

            &.rootcode:before {
              content: 'ROOTCODE EXAMPLE';
              display: block;
              padding-bottom: 8px;
              font-size: 12px;
              color: #dedede;
            }

            pre {
              padding: $docs-example-padding;
              margin: 0;
              background: $docs-example-bg;
              border: none;
              border-radius: $docs-example-border-radius;
              color: $docs-examples-color;
              font-size: 14px;
            }
          }

          .list {
            .list-table {
              background: $docs-examples-list-bg;
              border-radius: $docs-examples-list-border-radius;

              .item {
                border-top: 1px solid $docs-examples-list-inside-border-color;

                &:first-child {
                  border: none;
                }

                .item-label {
                  color: $docs-examples-list-label-color;

                  .item-detail {
                    color: $docs-examples-list-detail-color;
                  }
                }
              }
            }
          }
        }
      }

      &:first-child {
        .copy,
        .examples {
          border-top: none;
        }
      }

      &:after {
        content: '';
        clear: both;
        display: block;
      }

      pre {
        border-radius: 4px;
      }

      code {
        font-size: 13px;
      }
    }
  }

  &.guides {
    .header-sidebar {
      width: 450px;
      padding-left: 220px;
    }

    .sidebar {
      width: 450px;
      padding-left: 200px;
    }

    .api-content {
      left: 451px;
      min-width: 595px;

      .copy {
        max-width: 800px;
      }
    }

    blockquote {
      font-size: 14px;
      box-shadow: 0 0 10px 0 #dedede;
      padding-left: 44px;
      position: relative;
      border-radius: 4px;
      width: 98%;

      &::before {
        content: ' ';
        display: block;
        position: absolute;
        left: 14px;
        top: calc((100% - 23px) / 2);
        width: 26px;
        height: 23px;
        background-image: url('/assets/images/icons/mail.svg');
      }

      p {
        margin-bottom: 0;
        padding: 14px;
      }
    }

    .header {
      .header-language {
        display: none;
      }
    }

    .background {
      .background-inner {
        display: none;
      }
    }

    .content {
      section {
        .copy {
          width: inherit;
          float: none;

          h1 {
            font-size: $h1-font-size-new !important;
            font-weight: $h1-font-weight-new;
            margin-bottom: $h1-margin-bottom-new;
            line-height: $h1-line-height-new;
          }

          h2 {
            font-size: $h2-font-size-new !important;
            font-weight: $h2-font-weight-new;
            margin-bottom: $h2-margin-bottom-new;
            line-height: $h2-line-height-new;
          }

          h3 {
            font-size: $h3-font-size-new !important;
            font-weight: 300;
            margin-bottom: $h3-margin-bottom-new;
            line-height: $h3-line-height-new;
          }

          h4 {
            font-size: $h4-font-size-new !important;
            font-weight: $h4-font-weight-new;
            margin-bottom: $h4-margin-bottom-new;
            line-height: $h4-line-height-new;
          }

          h5 {
            font-size: $h5-font-size-new !important;
            font-weight: $h5-font-weight-new;
            margin-bottom: $h5-margin-bottom-new;
            line-height: $h5-line-height-new;
          }

          h6 {
            font-size: $h6-font-size-new !important;
            font-weight: $h6-font-weight-new;
            margin-bottom: $h6-margin-bottom-new;
            line-height: $h6-line-height-new;
          }
        }

        .examples {
          display: none;
        }
      }

      .markdown {
        img {
          max-width: 600px;
        }
      }

      code {
        background-color: #f6f6f6;
      }
    }
  }
}

.example:not(.light-code) {
  .hljs {
    filter: brightness(175%);
  }
}

$highlightingHueRotate: -20deg;

/* Base16 Atelier Savanna Dark - Theme */
/* by Bram de Haan (http://atelierbram.github.io/syntax-highlighting/atelier-schemes/savanna) */
/* Original Base16 color scheme by Chris Kempson (https://github.com/chriskempson/base16) */

/* Atelier-Savanna Comment */
.hljs-comment,
.hljs-quote {
  color: adjust-hue(#78877d, $highlightingHueRotate);
}

/* Atelier-Savanna Red */
.hljs-attribute,
.hljs-link,
.hljs-name,
.hljs-name,
.hljs-regexp,
.hljs-selector-class,
.hljs-selector-id,
.hljs-tag,
.hljs-template-variable,
.hljs-variable {
  color: adjust-hue(#b16139, $highlightingHueRotate);
}

/* Atelier-Savanna Orange */
.hljs-built_in,
.hljs-builtin-name,
.hljs-literal,
.hljs-meta,
.hljs-number,
.hljs-params,
.hljs-type {
  color: adjust-hue(#9f713c, $highlightingHueRotate);
}

/* Atelier-Savanna Green */
.hljs-bullet,
.hljs-string,
.hljs-symbol {
  color: adjust-hue(#489963, $highlightingHueRotate);
}

/* Atelier-Savanna Blue */
.hljs-section,
.hljs-title {
  color: adjust-hue(#478c90, $highlightingHueRotate);
}

/* Atelier-Savanna Purple */
.hljs-keyword,
.hljs-selector-tag {
  color: adjust-hue(#55859b, $highlightingHueRotate);
}

.hljs-addition,
.hljs-deletion {
  color: adjust-hue(#171c19, $highlightingHueRotate);
  display: inline-block;
  width: 100%;
}

.hljs-deletion {
  background-color: adjust-hue(#b16139, $highlightingHueRotate);
}

.hljs-addition {
  background-color: adjust-hue(#489963, $highlightingHueRotate);
}

.hljs {
  display: block;
  overflow-x: auto;
  background: transparent;
  color: adjust-hue(#87928a, $highlightingHueRotate);
  padding: 0.5em;
}

.hljs-emphasis {
  font-style: italic;
}

.hljs-strong {
  font-weight: bold;
}

.example.light-code {
  pre {
    background-color: #f6f6f6;
    padding: 12px;
  }

  .hljs-comment,
  .hljs-quote {
    color: #5f6d64;
  }

  .hljs-attribute,
  .hljs-link,
  .hljs-name,
  .hljs-name,
  .hljs-regexp,
  .hljs-selector-class,
  .hljs-selector-id,
  .hljs-tag,
  .hljs-template-variable,
  .hljs-variable {
    color: #b16139;
  }

  .hljs-built_in,
  .hljs-builtin-name,
  .hljs-literal,
  .hljs-meta,
  .hljs-number,
  .hljs-params,
  .hljs-type {
    color: #9f713c;
  }

  .hljs-bullet,
  .hljs-string,
  .hljs-symbol {
    color: #489963;
  }

  .hljs-section,
  .hljs-title {
    color: #478c90;
  }

  .hljs-keyword,
  .hljs-selector-tag {
    color: #55859b;
  }

  .hljs-addition,
  .hljs-deletion {
    color: #171c19;
    display: inline-block;
    width: 100%;
  }

  .hljs-deletion {
    background-color: #b16139;
  }

  .hljs-addition {
    background-color: #489963;
  }

  .hljs {
    display: block;
    overflow-x: auto;
    color: #526057;
    padding: 0.5em;
    background: #f6f6f6;
  }

  .hljs-emphasis {
    font-style: italic;
  }

  .hljs-strong {
    font-weight: bold;
  }
}

.documentation.guides .api-content section .examples {
  border-top: 0;
}
