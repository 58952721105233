.sidebar {
  flex: 0 0 $sidebar-width;
  background-image: $sidebar-background;
  border-right: $sidebar-border;
  padding: 24px;

  &-link {
    padding: 6px 8px;
    position: relative;
    left: -8px;
    margin-right: -16px;
    color: $body-color;
    user-select: none;
    display: block;

    &:hover {
      color: $primary;
      cursor: pointer;
    }

    &.icon {
      margin-top: 8px;
      border-top: $border;
      padding-top: 12px;

      img {
        margin-right: 8px;
      }

      span {
        position: relative;
        top: 2px;
      }
    }
  }

  .sidebar-link.active {
    background: #e9e9e9;
    border-radius: 5px;
  }

  .sidebar-link-disabled {
    color: #bbb;
    cursor: default;
  }
}
