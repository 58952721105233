@import 'module-card';

.module-not-enabled {
  width: 500px;
  border-radius: 4px;
  border: solid $border-width $border-color;
  margin-bottom: 24px;

  hr {
    margin-left: -1px;
    margin-top: 0;
    margin-bottom: 0;
  }

  h4 {
    margin-bottom: 0;
    padding: 20px;
  }

  button {
    margin-left: 20px;
    margin-top: 12px;
    margin-bottom: 12px;
  }
}

.product-module-key {
  text-align: left;
  padding-left: 4px;
  padding-right: 4px;
  display: inline;
  font-family: 'Source Code Pro', monospace;
  color: #4078fa;
  background-color: #f6f6f6;
  border: 1px solid #dedede;
  border-radius: 4px;
  vertical-align: middle;
}
