.policy-heading {
  display: flex;
  margin-bottom: 16px !important;

  h2 {
    margin-bottom: 0;
    margin-top: (48px - $h2-font-size) / 2;
  }

  .open-claim-title,
  .open-complaint-title {
    margin: 0 0 0 16px;
    @include fadeInAnimation;

    &::before {
      content: ' ';
      display: inline-block;
      width: 17px;
      height: 17px;
      border-radius: 50%;
      margin-right: 8px;
      position: relative;
      top: 3px;
    }
  }

  .open-claim-title::before {
    background: $yellow;
  }

  .open-complaint-title::before {
    background: $purple;
  }
}

.section {
  margin-bottom: 52px;
  &:not(:last-child) {
    border-bottom: 4px solid #eeeeee;
  }
  &:not(:first-child) {
    padding-top: 0;
  }
  padding: 24px;

  & > h2 {
    margin-bottom: 40px;
  }
}

.confirm-quote-summary {
  display: flex;

  &.hide {
    display: none;
  }

  img {
    margin-left: 12px;
    margin-right: 12px;
  }

  span {
    font-size: 12px;
    font-weight: $font-weight-bold;
    line-height: 1.5;
  }
}

.confirm-policy-cancel {
  display: flex;

  &.hide {
    display: none;
  }

  img {
    margin-left: 12px;
    margin-right: 12px;
  }

  span {
    font-size: 12px;
    font-weight: $font-weight-bold;
    line-height: 1.5;
  }
}
@import './billing';
