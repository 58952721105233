.card {
  border: 1px solid #dedede;
  padding-bottom: 8px;
  box-shadow: none !important;
}

.row-card-header {
  padding: 0px 0px 0px 0px;
  border-bottom: 1px solid #dedede;
  align-items: center;
  margin: 0px 0px 0px 0px;
  min-height: 50px;
}

.row-edit-button {
  line-height: 0px;
  height: 32px;
  width: 106px;
  border-width: 1px;
  margin: 10px 0px 10px 0px;
}

.row-add-button {
  border-width: 1px;
}

.row-note-button {
  line-height: 0px;
  width: 92px;
  height: 32px;
  border-width: 1px;
  margin: 10px 0px 10px 0px;
  padding-left: 9px;
}

.card-header {
  padding-left: 16px;
  margin: 20px 0px 20px 0px;
  font-weight: bold;
}

.row-title {
  padding-left: 16px;
  align-items: center;

  &.no-padding {
    padding-left: 0px;
  }
}

.col-title {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 28%;
  max-width: 240px;
  padding-right: 0px;
}

.col-value {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 72%;
  max-width: 72%;
  padding-left: 0px;
}

.row-hr {
  padding-left: 0px;
  padding-right: 0px;
}

.card-spacer {
  margin: 0.5rem 0 0.5rem 0;
  border: 0px solid #eaeaea !important;
  height: 5px;
}

.source-code-text {
  font-size: 12px;
  padding: 1px;
  background-color: #f6f6f6;
  border-radius: 5px;
  color: #7a7a7a;
  border-style: solid;
  border-width: 1.2px;
  border-color: #eaeaea;
  box-shadow: 1px;
  font-family: 'Source Code Pro', monospace;
}
