@import './policyholders/policyholders.scss';
@import './payment-methods/views/payment-method-card.scss';
@import './policies/styles/policies.scss';
@import './claims/claims.scss';
@import './claims/blocks/blocks.scss';
@import './general/general.scss';
@import './payments/payments.scss';
@import './management/management.scss';
@import './data-exports/data-exports.scss';
@import './shared/components/domain-entity-card/domain-entity-card.scss';
@import './shared/components/domain-entity-table/domain-entity-table.scss';
@import './shared/components/table-heading/table-heading.scss';
@import './payments/payments.scss';
@import './payments/views/payout-requests/payout-request-table.scss';
@import './payments/views/payout-requests/modals/billing-details-modal.scss';
@import './organizations/organizations.scss';
@import './communications/communications.scss';
@import './webhooks/webhooks.scss';
@import './applications/applications.scss';
@import './product-modules/views/product-moudule-api-docs/styles/docs.scss';
@import './data-adapter/data-adapter.scss';

$menu-border-color: #f6f6f6;

.org-popover {
  max-height: 330px;
  top: 200px !important;
  .user-popover-orgs-container {
    max-height: 330px - 50px - 53px - 51px;
  }

  .arrow {
    top: 87px !important;
  }

  .create-org {
    padding: 16px 16px 16px 24px;
    font-size: 14px;
    font-weight: bold;
    height: 51px;

    img {
      margin-right: 8px;
      margin-top: -3px;
    }

    a {
      color: $primary;

      &:hover {
        color: $primary;
      }
    }
  }

  &-heading {
    border-bottom: $border-width solid $menu-border-color;
    padding: 16px;
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 2px;
    height: 68px;
    line-height: 34px;
    cursor: pointer;

    &:hover {
      background: #fff;
      color: $primary;

      svg path {
        stroke: $primary;
      }
    }

    svg {
      transform: rotateY(180deg);
      margin-right: 8px;
      margin-top: -1px;
    }
  }
}

.user-popover,
.org-popover {
  width: 236px;

  .popover-body {
    padding: 0;
  }

  .arrow {
    display: none;
  }

  &-current {
    display: flex;
    flex-direction: row;
    border-bottom: $border-width solid $menu-border-color;
    padding: 16px;

    img {
      margin-right: 16px;
      width: 32px;
      height: 32px;
    }

    &-name {
      div {
        font-size: 14px;
        font-weight: bold;
        margin-bottom: 2px;
      }

      a {
        color: #7a7a7a;
        font-size: 10px;

        &:hover {
          color: $primary;
        }
      }
    }
  }

  &-links {
    border-bottom: $border-width solid $menu-border-color;
    padding: 16px;
    user-select: none;

    &:not(.no-color) {
      div,
      a {
        &.banking {
          color: #7f48e0 !important;
        }

        &.insurance {
          color: #1fc881 !important;
        }
      }
    }

    div,
    a {
      line-height: 27px;
      padding: 0 8px;
      display: block;

      & + div {
        margin-top: 4px;
      }

      img {
        margin-right: 8px;
        margin-top: -3px;
      }

      &:hover {
        background: #f6f6f6;
        cursor: pointer;
      }
    }
  }

  &-orgs {
    border-bottom: $border-width solid $menu-border-color;
    padding: 16px;

    &-title {
      display: flex;
      margin-bottom: 2px;
      padding-left: 8px;

      div {
        flex: 1;
        font-size: 14px;
        font-weight: bold;
      }

      a {
        font-size: 10px;
        line-height: 17px;
      }
    }

    &-container {
      max-height: 300px;
      overflow-y: auto;
    }

    &-org {
      margin: 4px 0;
      display: flex;
      padding: 4px 0 4px 8px;
      user-select: none;

      &:last-child {
        margin-bottom: 0;
      }

      &:hover {
        background: #f6f6f6;
        cursor: pointer;
      }

      &.current:hover {
        background: #fff;
        color: $primary;

        svg path {
          stroke: $primary;
        }
      }

      svg {
        float: right;
        margin-top: 1px;
      }

      .circle {
        width: 16px;
        height: 16px;
        background-color: #d8d8d8;
        margin-right: 8px;
        border-radius: 50%;
        background-size: cover;
      }

      div {
        font-size: 14px;
        line-height: 16px;
      }

      &.active {
        .circle {
          box-shadow: 0 0 1px 2px #a5ceff;
        }

        div {
          font-weight: bold;
        }
      }
    }
  }

  &-signout {
    padding: 16px 16px 16px 24px;
    font-size: 14px;
    font-weight: bold;
    height: 49px;

    a {
      color: #3d3d3d;

      &:hover {
        color: $primary;
      }
    }
  }
}

#secret-text-area {
  border: 0 !important;
}

.call-box {
  width: 100vw;
  flex: 0 0 52px;
  background-color: #1fc881;
  display: flex;
  line-height: 52px;
  color: $white;

  &-icon {
    margin-left: 21px;
    margin-right: 21px;
  }

  &-time {
    flex: 0 0 240px;
  }

  &-caller {
    display: flex;
    flex: 1;

    div {
      margin-right: 16px;

      &:first-child {
        margin-top: 16px;
        margin-bottom: 16px;
        border-radius: 10px;
        padding: 0 12px;
        background-color: #1baf6a;
        line-height: 20px;
        font-size: 12px;
        text-align: center;
      }

      &:last-child {
        font-weight: 300;
      }
    }
  }

  &-answerer {
    display: flex;

    div {
      margin-right: 16px;

      &:first-child {
        margin-top: 16px;
        margin-bottom: 16px;
        border-radius: 10px;
        padding: 0 12px;
        background-color: #1baf6a;
        line-height: 20px;
        font-size: 12px;
        text-align: center;
      }

      &:nth-child(2) {
        margin-right: 4px;
      }

      &:last-child {
        font-weight: 300;
      }
    }
  }
}
.webhook-attempt-view-body {
  max-width: 684px;
  max-height: 300px;
  text-align: left;
  background: #f7f7f9;
  border-radius: 10px;
  padding: 10px;
}

.webhook-attempt-table-column-width {
  width: 20%;
}

.webhook-attempt-table-column-key {
  max-width: 500px;
  word-break: break-all;
}

.module-data-row-font {
  font-weight: normal !important;
}

.module-data-row-border {
  border-bottom: none !important;
}

.hover-no-background {
  tr:hover {
    background: none !important;
  }
}

.fica-qa-header-wrapper {
  flex: 1;
  font-size: 23px;
  font-weight: 400;
}

.popup-menu-no-padding {
  padding: 0px !important;
}

.password-rules {
  padding-left: 35px;
  padding-right: 20px;
  padding-bottom: 5px;
  padding-top: 25px;
}

.heading-lean {
  display: flex;
  margin-bottom: 16px !important;
  align-items: center;

  h2 {
    margin-bottom: 0;
    margin-top: (48px - $h2-font-size) / 2;
  }

  .open-claim-title,
  .open-complaint-title {
    margin: 0 0 0 16px;
    @include fadeInAnimation;

    &::before {
      content: ' ';
      display: inline-block;
      width: 17px;
      height: 17px;
      border-radius: 50%;
      margin-right: 8px;
      position: relative;
      top: 3px;
    }
  }

  .open-claim-title::before {
    background: $yellow;
  }

  .open-complaint-title::before {
    background: $purple;
  }
}

.policy-ledger-view-modal {
  max-width: 500px;
}

#ledger table {
  th,
  td {
    &:nth-child(3),
    &:nth-child(4) {
      text-align: right;
      height: 40px;
    }
  }
}
// Zendesk button manual overrides
#launcher {
  right: 35px !important;
}
// Beamer button manual overrides
#beamerSelector {
  width: 45px !important;
  height: 45px !important;
  bottom: 15px;
  right: 5px !important;
  z-index: 999998 !important; // put this behind zendesk widget
}
